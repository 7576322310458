import React from "react";
import "./RightAnswer.scss";
import { Link } from "react-router-dom";

const RightAnswer = () => {
  return (
    <section className="pb-5">
      <div className="container">
        <div className="bg-purple right-answer-wrapper">
          <div className="row">
            <div className="col-md-8 text-start mb-4 mb-md-0">
              <h3 className="text-white right-answer-title mb-3">Can't Find The Right Answer?</h3>
              <p className="text-white mb-0">Our support team will be happy to help you.</p>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-start justify-content-md-end">
              <Link to={"/contactus"}>
                <button className="btn btn-light border-rad-45 fw-sembold text-purple fw-bold d-none d-md-block get-touch-btn">Get In Touch</button>
                <button className="btn btn-light border-rad-45 fw-sembold text-purple fw-semibold d-md-none get-touch-btn">Get In Touch</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightAnswer;
