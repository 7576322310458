import React, { useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import prodImg from "../../../assets/img/prod-story-img.png";
import prodImgMobile from "../../../assets/img/prod-story-img-mobile.png";
import better from "../../../assets/img/better.png";
import betterMobile from "../../../assets/img/better-mobile.png";
import saveIcon from "../../../assets/img/celebration.png";
import img1 from "../../../assets/img/sales-tools-1.svg";
import img2 from "../../../assets/img/sales-tools-2.svg";
import img3 from "../../../assets/img/sales-tools-3.svg";
import img4 from "../../../assets/img/sales-tools-4.svg";
import img5 from "../../../assets/img/sales-tools-5.svg";
import img6 from "../../../assets/img/sales-tools-6.svg";
import img7 from "../../../assets/img/sales-tools-6.svg";
import img8 from "../../../assets/img/sales-8.png";
import img9 from "../../../assets/img/sales-9.png";
import img10 from "../../../assets/img/sales-10.png";
import galaxyImg from "../../../assets/img/galaxy-img.svg";
import { GalaxyImg, GalaxyImgMobile, HeadphoneSolidIcon, NoCardIcon, PlayerIcon, QuotesIcon, SandClockIcon, TopRightArrowIcon, TopRightArrowIconWhite, YellowCheckIcon } from "../../../assets/img/svg";
import taj from "../../../assets/img/taj-img.png";
import radisson from "../../../assets/img/radisson.png";
import ira from "../../../assets/img/ira.png";
import jiva from "../../../assets/img/jiva.png";
import ihcl from "../../../assets/img/ihcl.png";
import hyatt from "../../../assets/img/hyatt.png";
import FAQ from "../../common/faq/FAQ";
import RightAnswer from "../../common/right-answer/RightAnswer";
import "./Homepage.scss";
import ProdAnimationLaptop from "../../../assets/video/DesktopAnimation.mp4";
import ProdAnimationMobile from "../../../assets/video/MobileAnimation.mp4";
import LazyLoad from "react-lazy-load";
import { useBaseURL } from "../../contexts/BaseURLContext";
import MobileVideo from "./MobileVideo";

const Homepage = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    better: `${baseURL}better.d8d8d8689ce672ac746d.png`,
    betterMobile: `${baseURL}better-mobile.712b4ce432b9b0a1fb9b.png`,
    galaxyImg: `${baseURL}galaxy-img.bc1a65e0601f527b393e545d1ace56cb.svg`,
    prodImg: `${baseURL}prod-story-img.369821eceb15ad746362.png`,
    prodImgMobile: `${baseURL}prod-story-img-mobile.598fdcae1b5310bf1789.png`,
    ProdAnimationMobile: `${baseURL}MobileAnimation.06cc5ca4e5a8b2d900ba.mp4`,
    ProdAnimationLaptop: `${baseURL}DesktopAnimation.9c6ace2bb115a7b55bc4.mp4`,
    img1: `${baseURL}sales-tools-1.9ef6cca509f9101502b25d231dd645da.svg`,
    img2: `${baseURL}sales-tools-2.faca3560addc4ecab7d9f2137db9d712.svg`,
    img3: `${baseURL}sales-tools-3.f73ab67802b446d7fba7fe0162d2fd19.svg`,
    img4: `${baseURL}sales-tools-4.be30b995e106c072b1fdf8140e9ae965.svg`,
    img5: `${baseURL}sales-tools-5.4c9af022b5e2ed905625d8b70e5497b9.svg`,
    img7: `${baseURL}sales-tools-6.3e7dd22f521de7dce461495e391849fc.svg`,
  };

  const laptopVideoRef = useRef(null);
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    const loadVideo = (videoRef) => {
      if (videoRef.current) {
        videoRef.current.load();
      }
    };

    loadVideo(laptopVideoRef);
    loadVideo(mobileVideoRef);
  }, []);

  let salesList = [
    {
      img: img1,
      title: "Dashboard",
      desc: "Empower decision-making with real-time \n data & analytics for bookings, revenue,\nand more.",
      mobdesc: "Empower decision-making with real-time data & analytics for bookings, revenue, and more.",
    },
    {
      img: img2,
      title: "Availability Calendar",
      desc: "Stay organised with a real-time, \n integrated calendar with all the data to \n stay organised.",
      mobdesc: "Stay organised with a real-time, integrated calendar with all the data to stay organised.",
    },
    {
      img: img3,
      title: "Inquiries",
      desc: "Streamline inquiries with creation, \n qualification, tracking, and efficient \n duplication management.",
      mobdesc: "Streamline inquiries with creation, qualification, tracking, and efficient duplication management.",
    },
    {
      img: img4,
      title: "Bookings",
      desc: "Effortlessly handle bookings, alerts, \n modifications, and cancellations for \n optimal efficiency.",
      mobdesc: "Effortlessly handle bookings, alerts, modifications, and cancellations for optimal efficiency.",
    },
    {
      img: img5,
      title: "Customer Management",
      desc: "Enhance guest experiences with \n comprehensive customer profiles and \n detailed inquiry and booking history.",
      mobdesc: "Enhance guest experiences with comprehensive customer profiles and detailed inquiry and booking history.",
    },
    {
      img: img7,
      title: "Reports & Analytics",
      desc: "Gain better insights into your business \n with detailed reports and useful analytics \n derived from historical data.",
      mobdesc: "Gain better insights into your business with detailed reports and useful analytics derived from historical data.",
    },
    // {
    //     img: img6,
    //     title: "Alerts, Notification & Reminders",
    //     desc: "Stay in the loop with instant updates through emails, text messages, and system alerts",
    //     mobdesc: "Stay in the loop with instant updates through emails, text messages, and system alerts",
    // },
    // {
    //     img: img8,
    //     title: "Venue Onboarding",
    //     desc: "Easily set up your venue with a straightforward onboarding process",
    //     mobdesc: "Easily set up your venue with a straightforward onboarding process",
    // },
    // {
    //     img: img9,
    //     title: "Lead Management",
    //     desc: "Maximize sales potential with seamless lead capturing, qualification, and nurturing.",
    //     mobdesc: "Maximize sales potential with seamless lead capturing, qualification, and nurturing.",
    // },
  ];

  function formatDescription(desc) {
    return desc.split("\n").map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  }

  let testimonials = [
    {
      text: "Excellent app, has transformed how I manage pickups, deliveries, and shipping. Highly recommend. Many thanks to Savin & Annie, who helped set up the app and ensured everything was running smoothly. Great tech support, which is greatly appreciated.",
      name: "John Robert",
      event: "Hibiscus CoastParty Hire",
      img: taj,
    },
    {
      text: "Needed help with Draft Orders and the team got on zoom with me and resolved the issue in less than 24 hours. Great service - thanks!",
      name: "Jenny Wilson",
      event: "Christies Bakery",
      img: radisson,
    },
    {
      text: "Using this app for more than a week now and can see upsells are happening. Best part is the support, they assisted over a Zoom call and answered all my queries.",
      name: "Katherine Willam ",
      event: "The RedEarth Roasters",
      img: ira,
    },
    {
      text: "Kumar is very helpful in setting it all up for me and explaining how to use the app. Would highly recommend for functionality and service.",
      name: "Katherine Willam",
      event: "Flower Head Events",
      img: jiva,
    },
    {
      text: "Using this app for more than a week now and can see upsells are happening. Best part is the support, they assisted over a Zoom call and answered all my queries.",
      name: "Katherine Willam ",
      event: "The RedEarth Roasters",
      img: ihcl,
    },
    {
      text: "Kumar is very helpful in setting it all up for me and explaining how to use the app. Would highly recommend for functionality and service.",
      name: "Katherine Willam",
      event: "Flower Head Events",
      img: hyatt,
    },
  ];

  let betterList = [{ text: "Venue Industry Expertise" }, { text: "All-In-One Tailored Solution" }, { text: "User-Friendly Interface" }, { text: "Comprehensive Feature Set" }, { text: "Proactive Support and Training" }, { text: "Customization Options and Scalability" }];

  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Chunk the salesList into groups of three
  const salesChunks = chunkArray(salesList, 3);

  return (
    <div className="overflow-hidden">
      <section className="bg-purple-img">
        <div className="homepage-section1-wrapper ">
          <h1 className="text-center text-white mb-28">Manage Bookings Effortlessly</h1>
          <p className="text-white text-center mb-50">
            India’s first dedicated venue management <br className="d-lg-none" /> software to engage leads better and <br className="d-none d-lg-block" /> deliver <br className="d-lg-none" /> unparalleled customer experience.
          </p>
          <div className="btns-wrapper">
            <Link to={"/talktosales"} className="mb-3 mb-lg-0 me-lg-3">
              <button className="btn btn-secondary border-rad-45 custom-btn fw-semibold">Get Started</button>
            </Link>
            <Link to={"/scheduledemo"}>
              <button className="btn btn-light text-dark-blue border-rad-45 custom-btn d-flex align-items-center justify-content-center">
                <PlayerIcon />
                <div className="ms-2 fw-semibold">Schedule Demo</div>
              </button>
            </Link>
          </div>
          <div className="homesection-bottom-utils-wrapper text-white">
            <div className="d-flex me-0 me-lg-4 mb-2 mb-lg-0">
              <NoCardIcon />
              <div className="ms-2 whitespace-nowrap title">No card required</div>
            </div>
            <div className="d-flex ">
              <div className="d-flex me-0 me-md-4 me-2">
                <HeadphoneSolidIcon />
                <div className="ms-2 whitespace-nowrap title">Dedicated venue experts</div>
              </div>
              <div className="d-flex">
                <SandClockIcon />
                <div className="ms-2 title">Quick set-up</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Better Section Start */}
      <section className="py-5 my-5 homepage-boozno-better">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 d-flex align-items-center">
              <div>
                <h2 className="section-title text-lg-start text-dark-blue mb-5 homepage-boozno-better-title">
                  What makes Boozno <br className="d-none d-lg-block" /> Better than others?
                </h2>
                <div className="better-list d-none d-lg-block">
                  {betterList.map((item, index) => (
                    <div className="better-list-item homepage-boozno-better-list-item" key={index}>
                      <YellowCheckIcon />
                      <div className="ms-2 title">{item.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-center">
                <img src={images.better} alt="" className="homepage-better-img d-none d-lg-block" draggable="false" />
                <img src={images.betterMobile} alt="" className="homepage-better-img d-block d-lg-none" draggable="false" />

                {/* <picture className="homepage-better-img d-block d-lg-none">
                  <source srcSet="../../../assets/img/better-mobile.svg" type="image/svg+xml" />
                </picture> */}
              </div>

              <div className="better-list d-block d-lg-none mt-4 ms-4">
                {betterList.map((item, index) => (
                  <div className="better-list-item" key={index}>
                    <YellowCheckIcon />
                    <div className="ms-2 title">{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Better Section End */}

      {/* Galaxy of Venues */}
      <section className="py-0 pt-md-5">
        <div className="container">
          <h2 className="section-title center text-dark-blue">
            Galaxy of venues: <br className="d-md-none" /> one Solution
          </h2>
          <div className="section-subtitle homepage-galaxy-venue-disc">Boozno offers a powerful suite of results-driven features suitable for diverse venues.</div>

          <span className="galaxy-img-wrapper d-block d-md-none">
            <GalaxyImgMobile />
          </span>
          <span className="galaxy-img-wrapper galaxy-img-wrapper-web d-none d-md-block">
            <img src={images.galaxyImg} alt="" className="w-100" />
          </span>
        </div>
      </section>
      {/* Galaxy of Venues End*/}

      {/* Product Story */}
      <section className="pb-5 pt-0 my-5">
        <div className="container my-lg-5 py-lg-5">
          <div className="row">
            <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end mb-4 mb-lg-0">
              <img src={images.prodImg} className="product-story-img d-none d-lg-block" alt="" />
              {/* <LazyLoad> */}
              <img src={images.prodImgMobile} className="product-story-img d-block d-lg-none mb-4" alt="" />
              {/* </LazyLoad> */}
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <div className="text-center text-lg-start homepage-product-story-content">
                <h2 className="section-title text-center text-lg-start text-dark-blue">Product Story</h2>
                <p className="">Boozno was shaped by the personal experiences and challenges faced by its founders while managing valuable venue spaces. In the fast-paced world of venue booking, every detail is crucial for success, whether it's a wedding or a corporate conference. We understand the struggles venues face because we've been there.</p>
                <p className="">Venues still rely on manual methods like spreadsheets, emails, and calls, which are time-consuming and prone to errors, leading to frustrations like double bookings and resource coordination headaches.</p>
                <p className="">Our journey began with a mission: to simplify venue management for businesses like yours. We're here to make your life easier, giving you back the time and energy you need to focus on what matters most.</p>
                <p className="">Experience the difference Boozno can make for your venues!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Product Story End */}

      {/* Management Problems Section */}
      <section className="py-5 pt-0 mb-5">
        <div className="container">
          <div className="venue-managemnet-wrapper">
            <h2 className="section-title center text-white mb-0">
              Venue Management Problems:
              <br className="d-none d-md-block" /> One <br className=" d-md-none" /> Solution
            </h2>
            {/* <div className="section-subtitle text-light-white">
              An advanced lead management in CRM will <br className="d-block d-md-none" />
              provide you with the following benefits including
            </div> */}
            <div className="venue-manage-img-wrapper">
              {/* <LazyLoad> */}
              <video ref={laptopVideoRef} src={images.ProdAnimationLaptop} className="w-100 d-none d-md-block" autoPlay playsInline loop muted preload="auto"></video>
              {/* </LazyLoad> */}
              {/* <LazyLoad> */}
              <MobileVideo src={images.ProdAnimationMobile} />
              {/* <video ref={mobileVideoRef} src={images.ProdAnimationMobile} className="w-100 d-block d-md-none" autoPlay playsInline loop muted preload="auto"></video> */}
              {/* </LazyLoad> */}
            </div>
          </div>
        </div>
      </section>
      {/* Management Problems Section End*/}

      {/* Save Strip */}
      <section className="py-3 my-5 save-strip-wrapper-bg">
        <div className="container">
          <div className="save-strip-wrapper">
            <img src={saveIcon} alt="" />
            <div className="text-white">
              Save up to 25% with our annual plans.&nbsp;&nbsp;
              <Link to={"/pricing"} className="text-white text-decoration-underline">
                Click here.
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Save Strip End */}

      {/* Collection of Sales Section Start */}
      <section className="py-5">
        <div className="container">
          <h2 className="section-title fs-lg-28 text-dark-blue mb-3">
            Sales Tools Designed for Enhanced <br className="d-none d-lg-block" />
            Productivity and Hassle-Free Experience
          </h2>
          <div className="section-subtitle">Efficient inquiry and venue management with an end-to-end, powerful suite of features.</div>

          <div className="d-block d-md-block">
            <div className="row mt-5 ">
              {salesList.map((data, index) => (
                <div className="col-md-4 mb-3 d-flex justify-content-center p-0" key={index}>
                  <div className="collection-sales-card">
                    <img src={data.img} className="mb-3 " alt="" />
                    <h5 className="mb-3">{data.title}</h5>
                    <p className="d-none d-md-block">{formatDescription(data.desc)}</p>
                    <p className="d-block d-md-none mb-0">{data.mobdesc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-5 d-none d-md-none">
            <OwlCarousel className="owl-theme" loop margin={10} nav dots={false} items={1}>
              {salesChunks.map((chunk, index) => (
                <div className="item" key={index}>
                  <div className="row">
                    {chunk.map((data, subIndex) => (
                      <div className="col-12 mb-3" key={subIndex}>
                        <div className="collection-sales-card">
                          <img src={data.img} className="mb-3" alt={data.title} />
                          <h5>{data.title}</h5>
                          <p>{data.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* Collection of Sales Section End */}

      {/* Quick links Section Start */}
      <section className="py-5 mb-lg-5">
        <div className="container">
          <div className="row mb-4 mb-lg-5">
            <div className="col-md-6">
              <h2 className="section-title text-center text-md-start">Quick Links</h2>
            </div>
            <div className="col-md-6">
              <div className="section-subtitle text-center text-md-end">
                We're a team of passionate individuals dedicated <br /> to creating positive change in the world
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <div className="view-plan-bg-img">
                <div className="mb-5">
                  <div className="text-white mb-2 fs-12">WANT MORE INSIGHTS</div>
                  <h3 className="text-white homepage-readblogs-title">Read our blogs</h3>
                </div>
                <div className="mt-5 pt-5">
                  <button className="btn btn-primary view-plan-btn">
                    <div className="me-2">View Blogs</div> <TopRightArrowIconWhite />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <div className="homepage-plan-card bg-beige mb-3 mb-md-3">
                    <div className="mb-5 text-grey2">
                      Find the best plan for your <br className="d-none d-md-block" /> venues
                    </div>
                    <Link to={"/pricing"} className="w-100 mt-lg-5">
                      <div className="view-plan-wrapper text-dark-blue">
                        <h5 className="mb-0 me-4 me-lg-0">View our Plans </h5>
                        <TopRightArrowIcon />
                      </div>
                    </Link>
                  </div>

                  <div className="homepage-plan-card bg-light-blue">
                    <div className="mb-5 text-grey2">
                      Get personalized advice by <br className="d-none d-md-block" /> our venue experts for tailored <br className="d-none d-md-block" /> insights
                    </div>
                    <Link to={"/talktosales"} className="w-100 mt-lg-5">
                      <div className="view-plan-wrapper text-dark-blue">
                        <h5 className="mb-0 me-4 me-lg-0">Talk to Sales</h5>

                        <TopRightArrowIcon />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="homepage-plan-card bg-purple-light h-100">
                    <div className="mb-5 text-grey2">
                      See innovation in action — <br className="d-none d-md-block" /> book your own software <br className="d-none d-md-block" /> demo today
                    </div>
                    <Link to={"/scheduledemo"} className="w-100">
                      <div className="view-plan-wrapper text-dark-blue">
                        <h5 className="mb-0 me-4 me-lg-0">Book a Demo </h5>
                        <TopRightArrowIcon />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Quick links Section End */}

      {/* Testimonials Section Start */}
      {/* <section className="py-5">
        <div className="container">
          <h2 className="section-title text-dark-blue mb-3">Testimonials</h2>
          <div className="section-subtitle">What our subscribers have to say about us</div>

          <div className="testimonials-container d-none d-lg-block mt-4 position-relative">
            <div className="gradient-bg-left"></div>
            <div className="mb-4">
              <Swiper
                loop={true}
                // peek={{ before: 100, after: 100 }}
                slidesPerView={1.5} // This allows half of the previous and next slides to show
                centeredSlides={true} // Centers the current slide
                // slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper">
                {testimonials.map((data, index) => (
                  <SwiperSlide key={index}>
                    {" "}
                    <div className="testimonials-card">
                      <div>
                        <div className="mb-1">
                          <QuotesIcon />
                        </div>
                        <div className="testimonial-text">"{data.text}"</div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="mb-1">{data.name}</h6>
                          <p className="mb-0">{data.event}</p>
                        </div>
                        <div>
                          <img src={data.img} alt="" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div>
              <Swiper
                loop={true}
                // peek={{ before: 100, after: 100 }}
                slidesPerView={1.5} // This allows half of the previous and next slides to show
                centeredSlides={true} // Centers the current slide
                // slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper">
                {testimonials.map((data, index) => (
                  <SwiperSlide key={index}>
                    {" "}
                    <div className="testimonials-card">
                      <div>
                        <div className="mb-1">
                          <QuotesIcon />
                        </div>
                        <div className="testimonial-text">"{data.text}"</div>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="mb-1">{data.name}</h6>
                          <p className="mb-0">{data.event}</p>
                        </div>
                        <div>
                          <img src={data.img} alt="" />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="gradient-bg-right"></div>
          </div>

          <div className="d-block d-lg-none">
            <OwlCarousel className="owl-theme" loop margin={10} nav dots={false} items={1}>
              {testimonials.map((item, index) => (
                <div className="item" key={index}>
                  <div className="testimonials-card">
                    <div>
                      <div className="mb-1">
                        <QuotesIcon />
                      </div>
                      <div className="testimonial-text">"{item.text}"</div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h6 className="mb-1">{item.name}</h6>
                        <p className="mb-0">{item.event}</p>
                      </div>
                      <div>
                        <img src={item.img} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section> */}
      {/* Testimonials Section End */}

      {/* FAQ Section Start */}
      <FAQ />
      <RightAnswer />
      {/* FAQ Section End */}
    </div>
  );
};

export default Homepage;
