import React, { useEffect, useRef } from "react";

const MobileVideo = ({ src }) => {
  const mobileVideoRef = useRef(null);

  useEffect(() => {
    const handleVideoEvents = () => {
      const video = mobileVideoRef.current;
      if (video) {
        video.addEventListener("waiting", () => {
          //   console.log("Video is waiting for more data...");
        });

        video.addEventListener("canplay", () => {
          //   console.log("Video can play");
        });

        video.addEventListener("error", (e) => {
          //   console.error("Video playback error", e);
        });
      }
    };

    handleVideoEvents();

    return () => {
      const video = mobileVideoRef.current;
      if (video) {
        video.removeEventListener("waiting", handleVideoEvents);
        video.removeEventListener("canplay", handleVideoEvents);
        video.removeEventListener("error", handleVideoEvents);
      }
    };
  }, []);

  return <video ref={mobileVideoRef} src={src} className="w-100 d-block d-md-none" autoPlay playsInline loop muted preload="metadata"></video>;
};

export default MobileVideo;
