// src/contexts/BaseURLContext.js
import React, { createContext, useContext } from "react";

const BaseURLContext = createContext();

export const BaseURLProvider = ({ children }) => {
  // PRODUCTION URL
  const baseURL = "https://booznostaticwebsitecdnendpoint.azureedge.net/";

  // UAT URL
  // const baseURL = "https://boozno-cdn.azureedge.net/static/media/";

  return <BaseURLContext.Provider value={baseURL}>{children}</BaseURLContext.Provider>;
};

export const useBaseURL = () => useContext(BaseURLContext);
