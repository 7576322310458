import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Homepage from "./components/pages/homepage/Homepage";
import PricingPage from "./components/pages/pricing/PricingPage";
import ProductPage from "./components/pages/products/ProductPage";
import ScrollToTop from "./utils/ScrollToTop";
import TitleUpdater from "./utils/TitleUpdater";
import AboutUsPage from "./components/pages/aboutus/AboutUsPage";
import NotFoundPage from "./components/pages/notfound/NotFoundPage";
import FaqPage from "./components/pages/faqs/FaqPage";
import LegalTabs from "./components/pages/legal-tabs/LegalTabs";
import TermsConditions from "./components/pages/legal-tabs/tabs/TermsConditions";
import PrivacyPolicy from "./components/pages/legal-tabs/tabs/PrivacyPolicy";
import CookiesPolicy from "./components/pages/legal-tabs/tabs/CookiesPolicy";
import GDPR from "./components/pages/legal-tabs/tabs/GDPR";
import MPI from "./components/pages/legal-tabs/tabs/MPI";
import DynamicTabContent from "./components/pages/faqs/DynamicTabContent";
import { productFaq, subPlanFaq, servicesFaq } from "./components/pages/faqs/data";
import TalktoSales from "./components/pages/forms/TalktoSales";
import ScheduleDemo from "./components/pages/forms/ScheduleDemo";
import ContactUs from "./components/pages/forms/ContactUs";
import ComingSoon from "./components/pages/notfound/ComingSoon";
import Security from "./components/pages/legal-tabs/tabs/Security";
import { BaseURLProvider, useBaseURL } from "./components/contexts/BaseURLContext";

function App() {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  return (
    <div className="App" style={{ "--base-url": baseURL }}>
      <ScrollToTop />
      <TitleUpdater />
      <BaseURLProvider>
        <Layout>
          <Routes>
            <Route path="/*" element={<NotFoundPage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/solution" element={<ProductPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/faqs" element={<FaqPage />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/talktosales" element={<TalktoSales />} />
            <Route path="/scheduledemo" element={<ScheduleDemo />} />
            <Route path="/blog" element={<ComingSoon />} />

            <Route path="/faqs" element={<FaqPage />}>
              <Route path="product" element={<DynamicTabContent data={productFaq} />} />
              <Route path="subscriptionplan" element={<DynamicTabContent data={subPlanFaq} />} />
              <Route path="services" element={<DynamicTabContent data={servicesFaq} />} />
            </Route>

            <Route path="/legal" element={<LegalTabs />}>
              <Route path="termsconditons" element={<TermsConditions />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="cookiespolicy" element={<CookiesPolicy />} />
              <Route path="gdpr" element={<GDPR />} />
              <Route path="mpi" element={<MPI />} />
              <Route path="security" element={<Security />} />
            </Route>
          </Routes>
        </Layout>
      </BaseURLProvider>
    </div>
  );
}

export default App;
