import React from "react";
import "./Ready.scss";
import img from "../../../assets/img/ready-img1.png";
import { Link } from "react-router-dom";
import { useBaseURL } from "../../contexts/BaseURLContext";

const ReadyToTry = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    img: `${baseURL}ready-img.1b1a9ab5a3418e26a3b7.png`,
  };
  return (
    <section className="py-5 mb-5">
      <div className="container px-3 px-md-auto">
        <div className="ready-container">
          <div className="bg-pink"></div>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-end ps-lg-5 pb-0 pb-lg-5 mt-4 mt-lg-0">
              <div className="mb-1 ps-4 ps-lg-4">
                <h1 className="ready-title text-start text-dark-blue">
                  Ready To Try <br className="d-lg-none" /> Boozno?
                </h1>
                <p className="ready-subtitle section-subtitle text-start">
                  Try Boozno and take back <br className="d-lg-none" /> control
                  of <br className="d-none d-lg-none" /> your day.
                </p>
                <div className="d-flex align-items-center">
                  <Link to={"/talktosales"}>
                    <button className="ready-cta btn btn-primary border-rad-45 me-2 me-lg-3">
                      Talk to Sales
                    </button>
                  </Link>
                  <Link to={"/talktosales"}>
                    <button className="ready-cta btn btn-outline-primary border-rad-45 d-none d-lg-block">
                      Get Started
                    </button>
                  </Link>
                  <Link to={""}>
                    <div className="text-purple d-none d-lg-none">
                      Get Started
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-end justify-content-lg-center">
              <img src={img} className="ready-img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReadyToTry;
