import React from "react";
import "./Modal.scss";
import Modal from "react-bootstrap/Modal";
import img from "../../../assets/img/modal-img.svg";
import { useBaseURL } from "../../contexts/BaseURLContext";

const ThankyouModal = ({ show, setShow }) => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    img: `${baseURL}ready-img.1b1a9ab5a3418e26a3b7.png`,
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Modal className="custom-modal-wrapper" show={show} size="" onHide={handleClose} centered>
        <Modal.Header closeButton>{/* <Modal.Title>Modal heading</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          <div className="custom-modal-body">
            <img src={img} alt="" className="mb-3" />
            <h1 className="thankyou-modal-title text-dark-blue">Thank You</h1>
            <p className="section-subtitle">
              Thank you for your request!
              <br />
              We will get back to you as quickly as we can.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ThankyouModal;
