import React, { useState, useEffect } from "react";
import "./Forms.scss";
import Common from "./Common";
import axios from "axios";
import ThankyouModal from "../../common/Modal/ThankyouModal";
import { Spinner } from "react-bootstrap";
import NetworkErrorModal from "../../common/Modal/NetworkErrorModal";
import UnresponsiveModal from "../../common/Modal/UnresponsiveModal";

const TalktoSales = () => {
  const [showThankyou, setThankyouShow] = useState(false);
  const [showNetworkError, setNetworkErrorShow] = useState(false);
  const [showUnresponsive, setUnresponsiveShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [errors, setErrors] = useState({});

  const formFields = [
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      placeholder: "Your first name",
      colClass: "col-lg-6",
      maxLength: 255,
      pattern: "^[a-zA-Z0-9' ]+$",
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Your last name",
      colClass: "col-lg-6",
      maxLength: 255,
      pattern: "^[a-zA-Z0-9' ]+$",
    },
    {
      label: "Email Address",
      name: "emailAddress",
      type: "email",
      placeholder: "Your email address",
      colClass: "col-lg-6",
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      placeholder: "Your phone number",
      colClass: "col-lg-6",
      maxLength: 10,
      pattern: "^[0-9]{10}$",
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
      placeholder: "Enter Company Name",
      colClass: "col-lg-6",
    },
    {
      label: "Team Member Size",
      name: "size",
      type: "select",
      options: [
        { option: "0-5", value: "0-5" },
        { option: "5-10", value: "5-10" },
        { option: "10-15", value: "10-15" },
        { option: "15-20", value: "15-20" },
        { option: "20-30", value: "20-30" },
        { option: "30-40", value: "30-40" },
        { option: "40-50", value: "40-50" },
      ],
      placeholder: "Select",
      colClass: "col-lg-6",
    },
  ];

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch("https://booznovenueqa.api.dweb.in/api/Account/GetTokenForStaticWebsite/", {
          method: "GET",
          headers: {
            Accept: "application/json",
            transKey: "3vT6zQ2wX8",
            secretKey: "7bD9eF2gH4jK6mNp",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setToken(result.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchToken();
  }, []);

  const initialFormData = formFields.reduce((acc, field) => {
    if (field.type === "select" && field.options.length > 0) {
      acc[field.name] = field.options[0].value;
    } else {
      acc[field.name] = "";
    }
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialFormData);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    formFields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = `Enter correct ${field.label.toLowerCase()}`;
      } else if (field.type === "email" && !/\S+@\S+\.\S+/.test(formData[field.name])) {
        newErrors[field.name] = `Enter a valid email address`;
      } else if (field.name === "phoneNumber" && !/^\d{10}$/.test(formData[field.name])) {
        newErrors[field.name] = `Enter a valid 10-digit phone number`;
      } else if (field.maxLength && formData[field.name].length > field.maxLength) {
        newErrors[field.name] = `${field.label} should be maximum ${field.maxLength} characters`;
      } else if (field.pattern && !new RegExp(field.pattern).test(formData[field.name])) {
        newErrors[field.name] = `Enter correct ${field.label.toLowerCase()}`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true); // Show the spinner when submitting

    if (!token) {
      setError("Failed to get the token.");
      setIsSubmitting(false);
      return;
    }

    const contactData = {
      FirstName: formData.firstName,
      LastName: formData.lastName,
      Email: formData.emailAddress,
      Mobile: formData.phoneNumber,
      CompanyName: formData.companyName,
      TeamSize: formData.size,
      Message: "",
    };

    try {
      const response = await axios.post("https://booznovenueqa.api.dweb.in/api/Account/StaticWebsiteEmailForTalkToSales/", contactData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setFormData(initialFormData); // Reset the form data
        setThankyouShow(true); // Show thank you modal
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 469) {
          setUnresponsiveShow(true);
        } else if (error.response.status === 503) {
          setNetworkErrorShow(true);
        } else {
          setError("Failed to send contact data.");
          console.error("Failed to send contact data", error);
        }
      } else {
        setError("Failed to send contact data.");
        console.error("Failed to send contact data", error);
      }
    } finally {
      setIsSubmitting(false); // Stop the spinner when done
    }
  };

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions((prev) => ({ ...prev, [name]: value }));
    inputChangeHandler(event); // Ensure the existing change handler is called
  };

  return (
    <div className="">
      <NetworkErrorModal show={showNetworkError} setShow={setNetworkErrorShow} />
      <UnresponsiveModal show={showUnresponsive} setShow={setUnresponsiveShow} />
      <ThankyouModal show={showThankyou} setShow={setThankyouShow} />
      <section className="py-5 h-auto d-flex align-items-center justify-content-center w-100">
        <div className="container d-flex align-items-center justify-content-center w-100">
          <div className="dynamic-form-wrapper">
            <div className="row">
              <div className="col-lg-8">
                <div className="left-form-wrapper">
                  <h1 className="section-title text-dark-blue text-center text-lg-start">Talk to Sales</h1>
                  <p className="section-subtitle text-center text-lg-start">Get in touch with our team if you have any sales queries.</p>

                  <form onSubmit={handleSubmit} className="custom-forms" noValidate>
                    <div className="row mb-3 main-form-wrapper">
                      {formFields.map((data, index) => (
                        <div className={`${data.colClass} mb-4`} key={index}>
                          <label className="form-label text-grey2">{data.label}</label>
                          {data.type === "select" ? (
                            <div className="select-wrapper">
                              <select name={data.name} className={`form-select custom-form-input ${errors[data.name] ? "is-invalid" : ""}`} value={formData[data.name]} onChange={handleSelectChange}>
                                {data.options.map((op, i) => (
                                  <option value={op.value} key={i} className={selectedOptions[data.name] === op.value ? "selected-option" : ""}>
                                    {op.option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <input type={data.type} name={data.name} className={`form-control custom-form-input ${errors[data.name] ? "is-invalid" : ""}`} placeholder={data.placeholder} value={formData[data.name]} onChange={inputChangeHandler} pattern={data.pattern} inputMode={data.name === "phoneNumber" ? "numeric" : undefined} maxLength={data.maxLength} required />
                          )}
                          {errors[data.name] && <div className="invalid-feedback">{errors[data.name]}</div>}
                        </div>
                      ))}
                    </div>

                    <button type="submit" className="btn btn-primary border-rad-45 w-100 py-3">
                      {isSubmitting ? (
                        <>
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2">Submitting...</span>
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
              <Common />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TalktoSales;
