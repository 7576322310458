import React, { useEffect, useState, useRef } from "react";
import bookingimg from "../../../../assets/img/Booking.png";
import availabilityimg from "../../../../assets/img/Calender.png";
import inquiriesimg from "../../../../assets/img/Inquiries.png";
import mtoolsimg from "../../../../assets/img/ManagementTools.png";
import onboardingimg from "../../../../assets/img/Onboarding.png";
import secimg from "../../../../assets/img/Security.png";
import { AvailabilityCalenderActiveIcon, AvailabilityCalenderIcon, BookingIcon, BookingIconActive, InquiryIcon, InquiryIconActive, OnboardingIcon, OnboardingIconActive, RightArrowCircleWhite, SecurityIcon, SecurityIconActive, SettingIcon, SettingIconActive } from "../../../../assets/img/svg";
import TabContent from "./TabContent";
import "./Tabs.scss";
import { useBaseURL } from "../../../contexts/BaseURLContext";

const ManagementSimplifiedTabs = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    availabilityimg: `${baseURL}Calender.8cc22c825fde97262e69.png`,
    inquiriesimg: `${baseURL}Inquiries.c572a567e540f1b519c7.png`,
    bookingimg: `${baseURL}Booking.757e33343939914b8632.png`,
    onboardingimg: `${baseURL}Onboarding.e72fcc2b0b0c07eb0821.png`,
    mtoolsimg: `${baseURL}ManagementTools.4b642d8b4f9a8636de0a.png`,
    secimg: `${baseURL}Security.3d7d8e0954fe6b73853a.png`,
    value3: `${baseURL}value-3.png`,
  };
  let tabContent = {
    AvailabilityCalender: {
      title: "Availability Calender",
      content: "Managing your venue is made simple with our Availability Calendar. You can easily navigate between single or all venues to see if they are available, booked, not operational, or closed. The calendar provides month and day views with statistics, enabling you to take instant actions such as adding reminders, creating inquiries, and updating the calendar.",
      img: images.availabilityimg,
      points: ["Real-time Availability", "Calendar Management", "Calendar View - Day/Month ", "Calendar View - Multiple venue ", "Stats and Summary", "Personalized Calendar View"],
    },
    Inquiries: {
      title: "Inquiries",
      content: "Create, track, and manage various inquiry types effortlessly with Boozno. Easily add and customise F&B packages and charge venue rentals. Include notes, manage reminders, and create visits and quotations. Get real-time updates, view billing summaries, and apply extra charges or discounts. Simplify your inquiry management with Boozno's all-in-one system.",
      img: images.inquiriesimg,
      points: ["Lead & Inquiry Management", "Duplicate Checks", "Real-Time Alerts", "Discount & Billing Summary", "Tentative Block Management", "Quotation/Proposal Management", "F&B Package Customisation"],
    },
    Booking: {
      title: "Bookings",
      content: "Our booking process is designed to minimize the possibility of mistakes. Before confirming a booking, users have the opportunity to review and verify all the details. Automated updates keep customers informed about any changes. It is easy to modify, cancel, and manage a detailed payment history. You can conveniently view data for all your current and past bookings.",
      img: images.bookingimg,
      points: ["Booking Management", "Waitlist Management", "Booking Conflict Alerts", "Modification & Cancellations", "Payment Management", "Booking Summary"],
    },
    Onboarding: {
      title: "Onboarding",
      content: "Boozno's platform is versatile and can be used by all types of venues. It can manage bookings for food, beverages, and venue rentals. Setting up and managing venues is effortless, with user-friendly controls that make it easy to use. The platform is tailored for easy management and subscribers can enjoy flexibility with unlimited venue and user additions. The streamlined process ensures efficient inquiry and booking management.",
      img: images.onboardingimg,
      points: ["Account Management", "Operational Days & Time", "F&B Management", "Amenities & Venue Rentals", "Tax Information", "Documents, T&C, Policies"],
    },
    ManagementTools: {
      title: "Management Tools ",
      content: "Streamline user access with customisable roles. Get detailed guidance on every software feature through accessible help and support. Easily import bulk data and generate customised reports for customers, inquiries, and bookings. Utilise the dynamic dashboard to gain insights into revenue, venues, bookings, occupancy, and user performances.",
      img: images.mtoolsimg,
      points: ["Dashboard Analytics", "Customer Management", "Data Import & Reports ", "Real-Time Updates", "Notifications & Reminders ", "Notes & Special Requests", "Role-Based Access & Permissions"],
    },
    Secuirty: {
      title: "Security",
      content: " Boozno's web app prioritises user data security with encryption, multi-factor authentication, and regular audits. Continuous monitoring ensures quick responses to suspicious activities, coupled with updates and access control for enhanced defence. Data backup ensures business continuity, offering resilience against various challenges.",
      img: images.secimg,
      points: ["Data Protection", "Data Security", "Multi-Factor Authentication", "Secure Login", "GST Verification", "Encrypted Data Storage"],
    },
  };

  const [tabList, setTabList] = useState([
    {
      title1: "Calender",
      title: "Availability Calender",
      icon: <AvailabilityCalenderIcon />,
      iconActive: <AvailabilityCalenderActiveIcon />,
      active: true,
      content: <TabContent content={tabContent.AvailabilityCalender} />,
    },
    {
      title: "Inquiries",
      icon: <InquiryIcon />,
      iconActive: <InquiryIconActive />,
      active: false,
      content: <TabContent content={tabContent.Inquiries} />,
    },
    {
      title: "Bookings",
      icon: <BookingIcon />,
      iconActive: <BookingIconActive />,
      active: false,
      content: <TabContent content={tabContent.Booking} />,
    },
    {
      title: "Onboarding",
      icon: <OnboardingIcon />,
      iconActive: <OnboardingIconActive />,
      active: false,
      content: <TabContent content={tabContent.Onboarding} />,
    },
    {
      title: "Management Tools ",
      icon: <SettingIcon />,
      iconActive: <SettingIconActive />,
      active: false,
      content: <TabContent content={tabContent.ManagementTools} />,
    },
    {
      title: "Security",
      icon: <SecurityIcon />,
      iconActive: <SecurityIconActive />,
      active: false,
      content: <TabContent content={tabContent.Secuirty} />,
    },
  ]);

  const tabListRef = useRef(null);
  const initialRender = useRef(true);

  const handleItemClick = (itemTitle) => {
    setTabList((prevState) => prevState.map((item) => (item.title === itemTitle ? { ...item, active: true } : { ...item, active: false })));
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const activeTab = document.querySelector(".custom-tab-item.active");
    if (activeTab) {
      activeTab.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [tabList]);

  // Preload SVGs using useEffect
  useEffect(() => {
    const preloadImages = () => {
      Object.keys(tabContent).forEach((key) => {
        const img = new Image();
        img.src = tabContent[key].img; // Preload the SVG source
      });
    };

    preloadImages(); // Call the preloading function once when the component mounts
  }, []); // Empty dependency array to ensure it only runs once when the component mounts

  return (
    <div className="custom-tab-wrapper">
      <div className="custom-tab-list" ref={tabListRef}>
        {tabList.map((item, index) => (
          <div className={`custom-tab-item ${item.active ? "active" : ""}`} key={index} onClick={() => handleItemClick(item.title)}>
            <div className="item">
              {item.active ? item.iconActive : item.icon}
              <div className="title">{item.title1 ? item.title1 : item.title}</div>
            </div>
            {item.active ? (
              <div className="custom">
                <div className="right-arrow-wrapper">
                  <RightArrowCircleWhite />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      <div className="custom-tab-content">
        {tabList.map((item, index) => (
          <div key={index}>{item.active ? <div>{item.content}</div> : ""}</div>
        ))}
      </div>
    </div>
  );
};

export default ManagementSimplifiedTabs;
