import React, { useState } from "react";
import { Form } from "react-bootstrap";
import img from "../../../assets/img/celebration.png";
import valuesImg1 from "../../../assets/img/every-step1.svg";
import valuesImg2 from "../../../assets/img/every-step2.svg";
import valuesImg3 from "../../../assets/img/every-step3.svg";
import { CheckIconPurple, Get1, Get2, Get3, Get4, GreyDashIcon, PurpleCheckIconStyle } from "../../../assets/img/svg";
import FAQ from "../../common/faq/FAQ";
import ReadyToTry from "../../common/ready-to-try/ReadyToTry";
import RightAnswer from "../../common/right-answer/RightAnswer";
import PlanCard from "./PlanCard";
import "./Pricing.scss";
import { featureList, plansList, planCards } from "./data";
import { Link } from "react-router-dom";
import { useBaseURL } from "../../contexts/BaseURLContext";

const PricingPage = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    valuesImg1: `${baseURL}every-step1.d785941dddcc1d4eb128719a30e00550.svg`,
    valuesImg2: `${baseURL}every-step2.7fe518b78d1eb1d55f92eab7885a39db.svg`,
    valuesImg3: `${baseURL}every-step3.aef7d3d1d865f40bb5402352da1a92e4.svg`,
  };

  const [planShow, setPlanShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = (e) => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      {/* Pricing Section */}
      <section className="py-5 mt-4">
        <div className="container mt-5">
          <h1 className=" text-dark-blue mb-lg-3 pricing-banner-title d-block d-lg-none">the perfect plan for your business</h1>
          <h1 className=" text-dark-blue mb-lg-3 pricing-banner-title d-none d-lg-block">Find the perfect plan for your business</h1>
          <div className="section-subtitle pricing-banner-subtitle text-center">Explore tailored plans to grow your business and stand out as a venue entrepreneur</div>
          <div className="save-offer-wrapper">
            <img src={img} alt="" />
            <div className="text ms-2 fw-semibold">Save 25% on annual plan </div>
          </div>
          <div className="pricing-switch-items d-flex align-items-center justify-content-center">
            <div className={`w-98 text-center ${!isChecked ? "text-darkgrey2 fw-semibold" : "text-light-grey"}`}>Bill Monthly</div>
            <div className="mx-2 switch-input-wrapper">
              <Form.Check type="switch" id="custom-switch" onChange={(e) => handleSwitchChange(e)} checked={isChecked} />
            </div>
            <div className={`w-98 text-center ${isChecked ? "text-darkgrey2 fw-semibold" : "text-light-grey"}`}>Bill Annualy</div>
          </div>
          <div className="mt-5 mb-4">
            <div className="row">
              <div className="col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <PlanCard featureList={featureList} data={planCards.starter} />
              </div>
              <div className="col-lg-4 mb-4">
                <PlanCard featureList={featureList} data={planCards.professional} isChecked={isChecked} />
              </div>
              <div className="col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <PlanCard featureList={featureList} data={planCards.enterprise} />
              </div>
            </div>
          </div>
          <div className="pricing-click-here-wrapper text-center">
            {planShow ? "To hide all the details" : "To view all the details"}
            <span className="text-purple ms-1 cursor-pointer" onClick={() => setPlanShow(!planShow)}>
              Click Here
            </span>
          </div>
          {planShow ? (
            <div className="py-4 mt-3">
              <div className="row px-4 plans-table-titles">
                <div className="col-5 col-lg-3 mb-3">
                  <div className="plans-table-title title">Compare plans</div>
                </div>
                <div className="col-2 col-lg-3 mb-3 text-center px-0 px-lg-2">
                  <div className="text-grey2 title">Starter</div>
                </div>
                <div className="col-3 col-lg-3 mb-3 text-center px-0 px-lg-2">
                  <div className="text-grey2 title">Professional</div>
                </div>
                <div className="col-2 col-lg-3 mb-3 text-center px-0 px-lg-2">
                  <div className="text-grey2 title">Enterprise</div>
                </div>
              </div>
              <div className="plans-list-wrapper px-4 py-3">
                {plansList.map((data, i) => (
                  <div className={`row list-item ${i === plansList.length - 1 ? "border-none" : ""}`} key={i}>
                    <div className="col-5 col-lg-3">
                      <div className="text-grey3">{data.title}</div>
                    </div>
                    <div className="col-2 col-lg-3">
                      <div className="h-100 d-flex align-items-center justify-content-center">{data.starter ? <PurpleCheckIconStyle /> : <GreyDashIcon />}</div>
                    </div>{" "}
                    <div className="col-3 col-lg-3">
                      <div className="h-100 d-flex align-items-center justify-content-center">{data.professional ? <PurpleCheckIconStyle /> : <GreyDashIcon />}</div>
                    </div>{" "}
                    <div className="col-2 col-lg-3">
                      <div className="h-100 d-flex align-items-center justify-content-center">{data.enterprise ? <PurpleCheckIconStyle /> : <GreyDashIcon />}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
      {/* Pricing Section End */}

      {/* 2 Cards Section */}
      <section className="py-5 my-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-6 mb-lg-0">
              <div className="pricing-2-cards-card text-center">
                <div className="custom-bg-incard"></div>
                <div className="px-3">
                  <h4 className="text-dark-blue mb-3 mb-lg-3">Need A Custom Build?</h4>
                  <p className="text-grey2">For an enterprise solution, get in touch with us.</p>
                </div>

                <Link to={"/contactus"}>
                  <button className="btn btn-outline-success border-rad-45 px-4 d-md-none">Get In Touch</button>
                  <button className="btn btn-outline-success border-rad-45 px-4 fw-semibold d-none d-md-block">Get In Touch</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pricing-2-cards-card text-center">
                <div className="custom-bg-incard"></div>
                <div className="px-4">
                  <h4 className="text-dark-blue mb-3 mb-lg-3">First time with Boozno?</h4>
                  <p className="text-grey2">
                    Take your business to new heights with Boozno <br className="d-none d-lg-block" />
                    and enjoy a risk free 30-day free trial along with <br className="d-none d-lg-block" /> other valuable resources.
                  </p>
                </div>
                <Link to={"/talktosales"}>
                  <button className="btn btn-success border-rad-45 px-4 px-4 py-2 d-md-none">Ask For Free Trial</button>
                  <button className="btn btn-success border-rad-45 px-4 fw-bold px-4 py-2 d-none d-md-block">Ask For Free Trial</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 2 Cards Section End*/}

      {/* Every Step Value Section */}
      <section className="py-5 my-lg-5">
        <div className="container">
          <h1 className="section-title text-dark-blue pricing-every-step-title">We're with you every step of the way</h1>
          <div className="section-subtitle">Guiding You Through Each Milestone.</div>
          <div className="row mt-5">
            <div className="col-lg-4 mb-4">
              <div className="pricing-values-card text-center">
                <div className="card-white-bg"></div>
                <img src={images.valuesImg1} className="mb-4" alt="" />
                <div className="px-3 pb-2">
                  <h5 className="text-dark-blue mb-3"> Venue Experts</h5>
                  <p className="text-grey2">
                    Don't know where to start? <br /> A dedicated support manager will help set up <br />
                    your account faster according to your <br /> specific goals and obstacles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="pricing-values-card text-center">
                <div className="card-white-bg"></div>
                <img src={images.valuesImg2} className="mb-4" alt="" />
                <div className="px-3 pb-2">
                  <h5 className="text-dark-blue mb-3">Dedicated Support</h5>

                  <p className="text-grey2">
                    Questions, suggestions, troubleshooting ⁠— <br /> our support team is always here to help <br /> you get the answers faster.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="pricing-values-card text-center">
                <div className="card-white-bg"></div>
                <img src={images.valuesImg3} className="mb-4" alt="" />
                <div className="px-3 pb-2">
                  <h5 className="text-dark-blue mb-3">Extensive Help Center</h5>
                  <p className="text-grey2">
                    Step-by-step tool guides, beginner- <br />
                    friendly glossary and quick solutions for <br /> your queries, accessible at your <br /> convenience.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Every Step Value Section End */}

      {/* Get by Subscribing */}
      <section className="py-5 pricing-subscription-cards pricing-subscription-cards-new">
        <div className="d-md-none">
          <h2 className="pricing-subscribe-title text-center text-lg-start text-dark-blue">
            What do you get by <br />
            subscribing paid plan
          </h2>
          <Link to={"/talktosales"}>
            <button className="btn btn-primary px-4 border-rad-45 d-block d-lg-block py-2 mx-auto mb-5 m-lg-0 ">Get Started</button>
          </Link>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 d-flex align-items-center justify-content-start">
              <div className="d-none d-md-block">
                <h2 className="pricing-subscribe-title section-title text-center text-lg-start text-dark-blue">
                  What do you get by <br />
                  subscribing to our paid plan
                </h2>
                <Link to={"/talktosales"}>
                  <button className="btn btn-primary px-4 border-rad-45 d-block d-lg-block py-2 mx-auto mb-5 m-lg-0 ">Get Started</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row values-cards-wrapper pricing-values-card">
                <div className="col-12 col-lg-6 mb-3 px-2">
                  <div className="collection-sales-card">
                    <div className="mb-4 mt-2">
                      <Get1 />
                    </div>
                    <h5 className="text-dark-blue mb-2 mb-lg-3">Gain full control</h5>
                    <p>
                      Take full control with our paid plan, <br className="d-none d-lg-block" /> empowering you to
                      <br className="d-none d-lg-block" /> effortlessly manage every <br className="d-none d-lg-block" /> aspect of your journey.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-3 px-2">
                  <div className="collection-sales-card custom-values-card">
                    <div className="mb-4 mt-2">
                      <Get2 />
                    </div>
                    <h5 className="text-dark-blue mb-2 mb-lg-3">Dedicated support</h5>
                    <p>
                      Receive personalized <br className="d-none d-lg-block" /> assistance from our dedicated <br className="d-none d-lg-block" /> support team, ensuring prompt <br className="d-none d-lg-block" /> responses to your questions <br className="d-none d-lg-block" /> and meeting your needs.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-4 px-2">
                  <div className="collection-sales-card">
                    <div className="mb-4 mt-3">
                      <Get3 />
                    </div>
                    <h5 className="text-dark-blue mb-2 mb-lg-3">Get 30-days trial</h5>
                    <p>
                      Enjoy a risk-free 30-day trial <br className="d-none d-lg-block" /> to experience premium <br className="d-none d-lg-block" />
                      features and get accustomed <br className="d-none d-lg-block" />
                      to the software.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-4 px-2">
                  <div className="collection-sales-card custom-values-card">
                    <div className="mb-4 mt-3">
                      <Get4 />
                    </div>
                    <h5 className="text-dark-blue mb-2 mb-lg-3">End to end Solution</h5>
                    <p>
                      Receive a comprehensive end- <br className="d-none d-lg-block" />
                      to-end solution, streamlining
                      <br className="d-none d-lg-block" /> every aspect for a seamless <br className="d-none d-lg-block" />
                      and efficient experience.
                    </p>
                  </div>
                </div>
              </div>
              <button className="btn btn-primary w-100 py-3 border-rad-45 d-none d-lg-none">Talk to Sales</button>
            </div>
          </div>
        </div>
      </section>
      {/* Get by Subscribing End */}

      <ReadyToTry />
      <FAQ />
      <RightAnswer />
    </div>
  );
};

export default PricingPage;
