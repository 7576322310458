import React from "react";
import img1 from "../../../assets/img/quick-setup.svg";
import img2 from "../../../assets/img/easy-use.svg";
import img3 from "../../../assets/img/d-support.svg";
import { useBaseURL } from "../../contexts/BaseURLContext";

const Common = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context
  const images = {
    img1: `${baseURL}quick-setup.05698bab7cb20eb433a5740c10b350c3.svg`,
    img2: `${baseURL}easy-use.a26b3cacc401cdde8c65de1c3ec91ebd.svg`,
    img3: `${baseURL}d-support.312ab1b93fba17dc27cbbeade6fb5bdd.svg`,
  };
  return (
    <div className="col-lg-4 ps-5 d-none d-lg-block forms-common-design">
      <div className="row justify-content-between h-100 form-img-wrapper">
        <div className="col-12">
          <div className="mb-4">
            <img className="mb-3" src={images.img1} alt="" />
            <h6 className="text-dark-blue">Quick Setup</h6>
            <p className="text-grey-2">Set up your account effortlessly with our step-by-step guide.</p>
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4">
            <img className="mb-3" src={images.img2} alt="" />
            <h6 className="text-dark-blue">Easy to use</h6>
            <p className="text-grey-2">Our platform is designed to be easy to use, even for first-time. users.</p>
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4">
            <img className="mb-3" src={images.img3} alt="" />
            <h6 className="text-dark-blue">Dedicated Support</h6>
            <p className="text-grey-2">Our team provides dedicated support every step of the way.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Common;
