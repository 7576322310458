import React from "react";
import "./Aboutus.scss";
import ReadyToTry from "../../common/ready-to-try/ReadyToTry";
import { Link } from "react-router-dom";

const AboutUsPage = () => {
  return (
    <div className="mt-lg-5">
      <section className="pt-5 mt-5 mb-lg-5">
        <div className="container">
          <h2 className="text-dark-blue aboutus-custom-title">
            With over 50 years of experience, our team <br />
            pioneers Venue Management SaaS with a unique approach
          </h2>
          <div>
            <h3 className="founder-story-title text-purple mb-4 text-center text-lg-start">Founder's Story</h3>
            <div className="about-us-content text-center text-lg-start">
              <p>More than a decade of experience, Ankit Agarwal is the driving force behind Boozno. As a seasoned entrepreneur, his expertise spans business management, events, media, marketing, and real estate, resulting in innovative tech solutions for both b2b & b2c markets. His passion for technology led to the creation of Boozno, revolutionizing venue management with its SaaS platform. Ankit's accolades include the prestigious "Debutante Business of the Year" award by ET, recognizing his innovative contributions to the Venue industry.</p>
              <p>Venturing into the venue industry in 2017, he managed bookings for diverse personal and corporate events, partnering with over 2500 venues and renowned hotel chains including IHCL, Hyatt, Marriott International, Hilton, Accor, ITC Limited, and The Leela across India.</p>
              <p>Beyond business, he thrives on building relationships and solving complex challenges. Enjoying connecting with start-up founders, he offers insights and makes impactful connections. Committed to organizing the venue industry, he seeks to simplify and enhance experiences for all stakeholders. When not reshaping the industry, he finds solace in exploring new places, meeting new people, and enjoying fitness activities and adventure sports.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 d-none d-lg-block">
        <div className="container">
          <div className="culture-wrapper">
            <h2 className="section-title text-white mb-3">Culture</h2>
            <p className="section-subtitle text-white mb-4">
              At Boozno, we foster a culture that empowers a humble, creative, and independent workforce. We're <br className="d-none d-lg-block" /> passionate about our subscribers and believe that collaboration and creativity are powerful tools to help <br className="d-none d-lg-block" /> them achieve business success. We cherish how each new hire enriches our culture, and we're excited to <br className="d-none d-lg-block" /> announce that we're hiring. <br />
              Join us in shaping the future of venue management!
            </p>
            <Link to={"/contactus"}>
              <button className="join-us-cta btn btn-outline-light border-rad-45">Join Us Now</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="py-5 d-block d-lg-none">
        <div className="culture-wrapper border-rad-none">
          <div className="container">
            <div className="text-center">
              <h2 className="section-title text-white mb-4">Culture</h2>
              <p className="section-subtitle text-white mb-4">
                At Boozno, we foster a culture that empowers a humble, creative, and independent workforce. We're passionate about our subscribers and believe that collaboration and creativity are powerful tools to help them achieve business success. We cherish how each new hire enriches our culture, and we're excited to announce that we're hiring. <br />
                Join us in shaping the future of venue management!
              </p>
              <Link to={"/contactus"}>
                <button className="join-us-cta btn btn-outline-light border-rad-45 px-5 py-3">Join Us Now</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <ReadyToTry />
    </div>
  );
};

export default AboutUsPage;
