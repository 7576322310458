import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <section className="py-5 fw-semibold">
      <div className="legal-tab-info-wrapper">
        <h5>Boozno Privacy Policy</h5>
        <p className="">This Privacy Policy (“Policy”) was last updated on June 12, 2024. We may change or update this policy at any time, and the same will be updated here. If you are a Boozno user or customer, we shall notify the changes or updates either by sending an email. Please ensure to read such notices carefully.</p>
        <p>We sincerely believe that you should always know what data we collect from you, the purposes for which such data is used, and that you should have the ability to make informed decisions about what you want to share with us. </p>
        <p>
          {" "}
          <span className="text-title-grey fw-bold">Our privacy commitment:</span> Boozno has never sold your information to someone else for advertising, or made money by showing you other people's ads, and we never will. This will always be our approach, and we will remain committed to it. Therefore, we want to be transparent about it and tell you what information we collect from you, what we do with it, who can access it, and what you can do about it.
        </p>
        <p>While we would strongly advise you to read the Policy in full, the following summary will give you a snapshot of the salient points covered herein:</p>
        <ul>
          <li>
            <p>
              This Policy details the critical aspects governing your personal data relationship with Boozno which is owned and operated by <span className="text-title-grey fw-bold">Boozno Technologies Private Limited</span>, a company incorporated under the laws of India, and having its registered office at B–1103, Ashford Royale, South Samuel Marg, Mulund–Goregaon Link Road, Nahur West, Mumbai – 400 078.
            </p>
          </li>
          <li>
            <p>
              Your personal data relationship with Boozno varies based on the capacity in which you interact with us/avail of our products and solutions (“<span className="text-title-grey fw-bold">Services”</span>). You could be: (i) a visitor to boozno.com (“<span className="text-title-grey fw-bold">Website”</span>) or any pages thereof (“<span className="text-title-grey fw-bold">Visitor</span>”); (ii) a person/entity availing of one of our Services (“<span className="text-title-grey fw-bold">Customer</span>”); or (iii) an employee/agent/representative/appointee of a Customer who uses the said Service (“<span className="text-title-grey fw-bold">User</span>”)
            </p>
          </li>
          <li>
            <p>Based on whether you are a Visitor, Customer or User, the type of data we collect and the purpose for which we use it will differ and this Policy details such variations.</p>
          </li>
          <li>
            <p>
              This Policy is a part of and should be read in conjunction with our{" "}
              <Link to={"/legal/termsconditons"} className="text-decoration-underline">
                Terms of Service.
              </Link>{" "}
            </p>
          </li>
        </ul>
        <p>
          If you have any queries or concerns with this Policy, please drop an email at{" "}
          <a href="mailto:legal@boozno.com" className="text-blue text-decoration-underline">
            legal@boozno.com.
          </a>
          &nbsp; If you do not agree with the Policy, we would advise you to not visit/use the Website.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>The Policy in full is as follows:</h5>
        <h6>Part I – Information Boozno collects and controls</h6>
        <h4>What information Boozno collects</h4>
        <p className="mb-2">We collect information about you only if we need the information for some legitimate purpose. Boozno will have information about you only if: </p>
        <ol>
          <li>
            <p className="mb-0">You have provided the information yourself.</p>
          </li>
          <li>
            <p className="mb-0">Boozno has automatically collected the information.</p>
          </li>
          <li>
            <p className="mb-0">Boozno has obtained the information from a third party. Below we describe the various scenarios that fall under each of those three categories and the information collected in each one.</p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Information that you provide us:</h5>
        <ol>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Account signup: </span>When you sign up for an account to access one or more of our services, we ask for information like your name, contact number, email address, company name, GST no, entity name, country, state, city and zip code to complete the account signup process. You'll also be required to verify your account via mobile or email OTP to access the created account. After signing up, you will have the option of changing the primary mobile, email address and other details via account settings.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Event registrations and other form submissions: </span> We record information that you submit when you (i) register for any event, including webinars or seminars, (ii) subscribe to our newsletter/blogs or any other mailing list, (iii) submit a form in order to download any product, whitepaper, or other materials, (iv) participate in contests or respond to surveys, or (v) submit a form to request customer support, get a quote or to contact Boozno for any other purpose.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Payment processing: </span> When you buy something from us, we may ask you to provide your name, contact information, and credit card information or other payment account information. When you submit your card information, we store the name and address of the cardholder, the expiry date and the last four digits of the credit card number. We do not store the actual credit card number. For quick processing of future payments, if you have given us your approval, we may store your credit card information or other payment information in an encrypted format in the secured servers of our Payment Gateway Service Providers.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Testimonials: </span> When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. If you wish to update or delete your testimonial, you can contact us at{" "}
              <a href="mailto:legal@boozno.com" className="text-blue text-decoration-underline">
                legal@boozno.com.
              </a>{" "}
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Interactions with Boozno: </span>We may record, analyze and use your interactions with us, including email, telephone, and chat conversations with our sales and customer support professionals, for improving our interactions with you and other customers.
            </p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Information that we collect automatically:</h5>
        <ol>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Information from browsers, devices and servers:</span> When you visit our websites, we collect information that web browsers, mobile devices and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer and mobile network information. We include these in our log files to understand more about visitors to our websites.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Information from cookies and tracking technologies: </span>We use temporary and permanent cookies to identify users of our services and to enhance user experience. We embed unique identifiers in our downloadable products to track usage of the products. We also use cookies, beacons, tags, scripts, and other similar technologies to identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for targeted visitor and user engagement by tracking your activities on our websites. We mostly use first–party cookies and do not use third–party cookies or other third–party tracking technologies on our websites for non–essential or intrusive tracking. You can learn more about the cookies used on our websites here. You can also learn more about Boozno's stance against non–essential and intrusive third–party cookies and tracking technology here. We also use
              first–party Local Storage Objects (LSOs) such as HTML5 to store content information and preferences to provide certain features.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Information from application logs and mobile analytics: </span> We collect information about your use of our products, services and mobile applications from application logs and in–house usage analytics tools, and use it to understand how your use and needs can improve our products. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, storage utilized, user settings and configurations, and devices used to access and their locations.
            </p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Information that we collect from third parties:</h5>
        <ol>
          <li>
            <p className="mb-0">
              <span className="text-title-grey fw-bold">Signups using federated authentication service providers:</span>
              You can log in to Boozno Services using supported federated authentication service providers such as LinkedIn, Microsoft and Google. These services will authenticate your identity and give you the option to share certain personal information with us, such as your name and email address.
            </p>
          </li>
          <li>
            <p className="mb-0">
              <span className="text-title-grey fw-bold">Referrals:</span>
              If someone has referred any of our products or services to you through any of our referral programs, that person may have provided us with your name, email address and other personal information. You may contact us at <a href="mailto:legal@boozno.com">legal@boozno.com</a> to request that we remove your information from our database. If you provide us information about another person, or if another person gives us your information, we will only use that information for the specific reason for which it was provided to us.
            </p>
          </li>
          <li>
            <p className="mb-0">
              <span className="text-title-grey fw-bold">Information from our reselling partners and service providers:</span>
              If you contact any of our reselling partners, or otherwise express interest in any of our products or services to them, the reselling partner may pass your name, email address, company name and other information to Boozno. If you register for or attend an event that is sponsored by Boozno, the event organizer may share your information with us. Boozno may also receive information about you from review sites if you comment on any review of our products and services, and from other third–party service providers that we engage for marketing our products and services.
            </p>
          </li>
          <li>
            <p className="mb-0">
              <span className="text-title-grey fw-bold">Information from social media sites and other publicly available sources:</span>
              When you provide feedback or reviews about our products, interact, or engage with us on marketplaces, review sites or social media sites such as Facebook, Twitter, LinkedIn and Instagram through posts, comments, questions and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our products, better understand user reactions and issues, or to reproduce and publish your feedback on our websites. We must tell you that once collected, this information may remain with us even if you delete it from these sites. Boozno may also add and update information about you, from other publicly available sources.
            </p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Purposes for using information</h5>
        <p>In addition to the purposes mentioned above, we may use your information for the following purposes:</p>
        <ul>
          <li>
            <p className="mb-0">To communicate with you (such as through email) about products that you have downloaded and services that you have signed up for, changes to this Privacy Policy, changes to the Terms of Service, or important notices;</p>
          </li>
          <li>
            <p className="mb-0">To keep you posted on new products and services, upcoming events, offers, promotions and other information that we think will be of interest to you;</p>
          </li>
          <li>
            <p className="mb-0">To ask you to participate in surveys, or to solicit feedback on our products and services;</p>
          </li>
          <li>
            <p className="mb-0">To set up and maintain your account, and to do all other things required for providing our services, such as enabling collaboration, providing website and mail hosting, and backing up and restoring your data;</p>
          </li>
          <li>
            <p className="mb-0">To understand how users use our products and services, to monitor and prevent problems, and to improve our products and services;</p>
          </li>
          <li>
            <p className="mb-0">To provide customer support, and to analyze and improve our interactions with customers;</p>
          </li>
          <li>
            <p className="mb-0">To detect and prevent fraudulent transactions and other illegal activities, to report spam, and to protect the rights and interests of Boozno, Boozno’s users, third parties and the public;</p>
          </li>
          <li>
            <p className="mb-0">To update, expand and analyze our records, identify new customers, and provide products and services that may be of interest to you;</p>
          </li>
          <li>
            <p className="mb-0">To analyze trends, administer our websites, and track visitor navigations on our websites to understand what visitors are looking for and to better help them;</p>
          </li>
          <li>
            <p className="mb-0">To monitor and improve marketing campaigns and make suggestions relevant to the user.</p>
          </li>
        </ul>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Your choice in information use</h5>
        <p>
          <span className="text-title-grey fw-bold">Opt out of non–essential electronic communications:</span> You may opt out of receiving newsletters and other non–essential messages by using the ‘unsubscribe’ function included in all such messages. However, you will continue to receive essential notices and emails such as account notification emails (password change, renewal reminders, etc.), security incident alerts, security and privacy update notifications, and essential transactional and payment related emails.
        </p>
        <p>
          <span className="text-title-grey fw-bold">Disable cookies:</span> You can disable browser cookies before visiting our websites. However, if you do so, you may not be able to use certain features of the websites properly.
        </p>
        <p>
          <span className="text-title-grey fw-bold">Optional information:</span> You can choose not to provide optional profile information such as your photo. You can also delete or change your optional profile information. You can always choose not to fill in non–mandatory fields when you submit any form linked to our websites.
        </p>
        <p>
          <span className="text-title-grey fw-bold">Who we share your information with:</span> All Boozno group entities have access to the information covered above. We do not sell any personal information. We share your information only in the ways that are described in this Privacy Policy, and only with parties who adopt appropriate confidentiality and security measures.
        </p>
        <p>
          <span className="text-title-grey fw-bold">Employees and independent contractors:</span> Employees and independent contractors of relevant Boozno entities have access to the information covered above on a need-to-know basis. We require all employees and independent contractors of Boozno group entities to follow this Privacy Policy for personal information that we share with them.
        </p>
        <p>
          <span className="text-title-grey fw-bold">Third–party service providers:</span> We may need to share your personal information and aggregated or de-identified information with third-party service providers that we engage, such as marketing and advertising partners, event organizers, web analytics providers and payment processors. These service providers are authorized to use your personal information only as necessary to provide these services to us.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Retention of information</h5>
        <p>
          We retain your personal information for as long as it is required for the purposes stated in this Privacy Policy. Sometimes, we may retain your information for longer periods as permitted or required by law, such as to maintain suppression lists, prevent abuse, if required in connection with a legal claim or proceeding, to enforce our agreements, for tax, accounting, or to comply with other legal obligations. When we no longer have a legitimate need to process your information, we will delete or anonymize your information from our active databases. We will also securely store the information and isolate it from further processing on backup discs until deletion is possible. If you are a Customer, please be advised that: (i) you will need to inform your Leads about how you store and deal with any data you collect from them using one of our Services, in compliance with applicable laws and (ii) after you terminate your usage of a Service, we may, unless legally prohibited, delete
          all data provided or collected by you (including Lead information) from our servers.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Automation and Artificial Intelligence</h5>
        <p>In order to provide enhanced productivity and predictive capabilities to our users, we employ a variety of technologies such as regex parsing, template matching, artificial intelligence and machine learning. In keeping with Boozno's promise not to exploit your data in a way that is not respectful of your privacy and confidentiality expectations, we make only the following limited use of service data for these technologies: (i) using anonymized crops of service data to improve accuracy of the algorithms; and (ii) using your organization's data for developing models specific for your organization. Our automation and artificial intelligence technologies are mostly powered by our own organization's data such as internal communications, communications with customers and internal documents as well as free and paid external sources.</p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Compelled Disclosure</h5>
        <p>In addition to the purposes set out in the Policy, we may disclose any data we collected or processed from you if it is required:</p>
        <ul>
          <li>
            <p>Under applicable law or to respond to a legal process, such as a search warrant, court order, or subpoena;</p>
          </li>
          <li>
            <p>To protect our safety, your safety or the safety of others or in the legitimate interest of any party in the context of national security, law enforcement, litigation, criminal investigation or to prevent death or imminent bodily harm;</p>
          </li>
          <li>
            <p>To investigate fraud or violation of our Policies;</p>
          </li>
          <li>
            <p>If required in connection with legal proceedings brought against Boozno, its officers, employees, affiliates, customers or vendors; or</p>
          </li>
          <li>
            <p>To establish, exercise, protect, defend and enforce our legal rights.</p>
          </li>
        </ul>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Business Transfers</h5>
        <p>We do not intend to sell our business. However, in the unlikely event that we sell our business or get acquired or merged, we will ensure that the acquiring entity is legally bound to honor our commitments to you. We will notify you via email or through a prominent notice on our website of any change in ownership or in the uses of your personal information and service data. We will also notify you about any choices you may have regarding your personal information and service data.</p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Compliance with this Privacy Policy</h5>
        <p>We make every effort, including periodic reviews, to ensure that personal information you provide is used in conformity with this Privacy Policy. If you have any concerns about our adherence to this Privacy Policy or the manner in which your personal information is used, kindly write to us at legal@boozno.com. We'll contact you, and if required, coordinate with the appropriate regulatory authorities to effectively address your concerns.</p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Notification of changes</h5>
        <p>
          We may modify the Privacy Policy at any time, upon notifying you through a service announcement or by sending an email to your primary email address. If we make significant changes to the Privacy Policy that affect your rights, you will be provided with at least 30 days' advance notice of the changes by email to your primary email address. However, if you have not verified your email address, you may miss important notifications that we send through email. If you think that the updated Privacy Policy affects your rights with respect to your use of our products or services, you may terminate your use by sending us an email within 30 days. Your continued use after the effective date of changes to the Privacy Policy will be deemed to be your agreement to the modified Privacy Policy. You will not receive email notification of minor changes to the Privacy Policy. If you are concerned about how your personal information is used, you should check back at{" "}
          <a href="https://www.boozno.com/privacypolicy">https://www.boozno.com/privacypolicy</a> periodically.
        </p>
      </div>
      <p>
        In case of grievances you may drop an email to &nbsp;
        <a href="mailto:legal@boozno.com" className="text-blue text-decoration-underline">
          legal@boozno.com
        </a>{" "}
        for resolution.
      </p>
    </section>
  );
};

export default PrivacyPolicy;
