import React, { useState } from "react";
import { CheckIconPurple, GreyCheckIconStyle } from "../../../assets/img/svg";
import { Link } from "react-router-dom";

const PlanCard = ({ featureList, data, isChecked }) => {
  const [allFeatures, setAllFeatures] = useState(true);
  const [count, setCount] = useState(1);
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  // Function to increase the count with a maximum limit
  const increment = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString("en-IN"); // Format number as per Indian numbering system
  };

  return (
    <div className="w-100">
      {/* Laptop CARD */}
      <div className={`plan-card ${data.main ? "border-purple" : ""} d-block d-lg-block`}>
        <div className="d-flex align-items-center justify-content-between top-wrapper">
          <h5 className="mb-0">{data.title}</h5>
          <div className="text-dark-blue venues">
            {data.venues} {data.venues > 1 ? "Venues" : "Venue"}
          </div>
        </div>

        {data.main ? (
          <div className="d-flex align-items-end justify-content-start text-purple">
            <div className="title">
              {isChecked ? (
                <div className="d-flex align-items-center">
                  {" "}
                  <del>₹ {formatNumber(48000 * count)}</del> &nbsp; ₹ {formatNumber(36000 * count)}
                </div>
              ) : (
                <div>₹{formatNumber(4000 * count)}</div>
              )}{" "}
            </div>
            <div className="mb-1">/User</div>
          </div>
        ) : (
          <div> </div>
        )}

        <div className="mb-3">
          <h6>{data.subtitle}</h6>
        </div>
        <div className="card-list mb-4">
          {data.featureList.map((data, index) => (
            <div className="d-flex align-items-center mb-3" key={index}>
              <CheckIconPurple />
              <div className=" card-list-text">{data}</div>
            </div>
          ))}
        </div>
        {data.main ? (
          <div className="no-of-users-wrapper">
            <h6 className="mb-0">No. of Users</h6>
            <div className="counter-wrapper">
              <div className={`icon-wrapper no-select fw-bold br ${count === 1 ? "text-light-grey cursor-nodrop" : "text-dark-blue"}`} onClick={decrement}>
                -
              </div>
              <div className="px-2 text">{count}</div>
              <div className={`icon-wrapper no-select fw-bold bl ${count === 10 ? "text-light-grey cursor-nodrop" : "text-dark-blue"}`} onClick={increment}>
                +
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {data.main ? (
          <Link to={"/talktosales"}>
            <button className="btn btn-primary border-rad-45 w-100 py-3 fw-semibold">Continue</button>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PlanCard;
