export let featureList = ["Reporting and Analytics", "Create Enquiry", "Duplicate Enquiry", "Create Quotation", "Create Visit", "Add Discount", "Add Menu", "User Management", "User Roles & Accessible", "Assign lead owner", "Timeline", "Contract document"];
export let plansList = [
  {
    title: "Dashboard & Analytics",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Customer Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Lead Capturing & Qualification",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry Creation - Single & Multiple (Day & Slots)",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry Creation - Long term",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry Nurturing, Qualification, Tracking",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Duplicate Check",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Prioritization & Source Tracking",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry Modification",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Inquiry & Booking History",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Real-Time Alerts",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Appointment Scheduling",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Tentative Block Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "F&B Package Selection",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "F&B Package Customisation",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Notes",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Special Request",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Quotation/Proposal Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Billing Summary",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Discount Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Additional Charges Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Booking Conflict Alerts",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Booking Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Waitlist Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Booking Modification & Cancellation",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Payment Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Payment History",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Refund Details",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "F&B Menu Selection",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Calendar Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Calendar View - Day/Month",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Calendar View - All Venue",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Notifications (SMS, Email, System)",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Reminders",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Account Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Operational Days & Time - Multi Slot",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Venue Rental Setup",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "F&B Package Management",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "F&B Menu Management",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Tax Details",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Documents, T&C, Policies",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Role-Based Access & Permissions",
    starter: true,
    professional: true,
    enterprise: true,
  },
  {
    title: "Data Import",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Customised Reports",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Historical Reporting",
    starter: false,
    professional: true,
    enterprise: true,
  },
  {
    title: "Billing & Invoice Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Mobile Access",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Third Party Integrations",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "On day tracking and management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Budgeting/Forecasting",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Virtual tour guide",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Call recording and timeline history",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Marketing Automation",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Online Payments",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Performance Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Accounting Integration",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Attendance Tracking",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Live Chat",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Marketing Analytics",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Payment Processing",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Activity Timeline",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Chatbots",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Seating Arrangements",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Loyalty Programs",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Venue Layouts and Floor Plans",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Website Integration",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Task Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Support Ticket Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Data Migration",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Email Integration",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Email Marketing",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Escalation",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Feedback Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Auto-Lead Distribution",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Multi-Language",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Real-Time Chat",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Referral Tracking",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Response Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
  {
    title: "Sales Pipeline Management",
    starter: false,
    professional: false,
    enterprise: true,
  },
];
export let planCards = {
  starter: {
    title: "Starter",
    venues: 1,
    users: false,
    main: false,
    featureList: ["Dashboard & Analytics", "Lead & Inquiry Management", "Calendar Management", "Duplication check", "F&B Package Management", "Real-Time Alerts", "Inquiry & Booking Modifications", "Role-Based Access & Permissions", "Prioritization & Source Tracking", "Notification & Reminders"],
  },
  professional: {
    title: "Professional",
    subtitle: "Starter Plan +",
    venues: 5,
    // price: 36,000,
    users: true,
    main: true,
    featureList: ["Quotation & Proposals", "Booking Conflict Alerts", "Appointment Scheduling", "Discounts  & Billing Summary", "Tentative Block Scheduling", "Waitlist Management", "F&B Menu Selection", "Payment Management", "Additional Charges Management", "Data Import & Customised Reports"],
  },
  enterprise: {
    title: "Enterprise",
    subtitle: "Professional Plan +",
    venues: 10,
    users: false,
    main: false,
    featureList: ["Billing & Invoice Management", "Marketing Automation", "Mobile Access", "Third Party Integrations", "On day tracking and management", "Budgeting/Forecasting", "Virtual tour guide", "Online Payments", "Call recording and timeline history", "Performance Management"],
  },
};
