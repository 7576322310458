import React, { useState, useEffect, useRef } from "react";
import "./Forms.scss";
import Common from "./Common";
import axios from "axios";
import ThankyouModal from "../../common/Modal/ThankyouModal";
import { Spinner } from "react-bootstrap";
import NetworkErrorModal from "../../common/Modal/NetworkErrorModal";
import UnresponsiveModal from "../../common/Modal/UnresponsiveModal";

const ScheduleDemo = () => {
  const [minDate, setMinDate] = useState("");
  const [showThankyou, setThankyouShow] = useState(false);
  const [showNetworkError, setNetworkErrorShow] = useState(false);
  const [showUnresponsive, setUnresponsiveShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState(null); // State to store the token
  const [errors, setErrors] = useState({});

  const formFields = [
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      placeholder: "Your first name",
      colClass: "col-lg-6",
      maxLength: 255,
      pattern: "^[a-zA-Z0-9' ]+$",
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      placeholder: "Your last name",
      colClass: "col-lg-6",
      maxLength: 255,
      pattern: "^[a-zA-Z0-9' ]+$",
    },
    {
      label: "Email Address",
      name: "emailAddress",
      type: "email",
      placeholder: "Your email address",
      colClass: "col-lg-6",
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      placeholder: "Your phone number",
      colClass: "col-lg-6",
      maxLength: 10,
      pattern: "^[0-9]{10}$",
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
      placeholder: "Enter Company Name",
      colClass: "col-lg-6",
    },
    {
      label: "Team Member Size",
      name: "size",
      type: "select",
      options: [
        { option: "0-5", value: "0-5" },
        { option: "5-10", value: "5-10" },
        { option: "10-15", value: "10-15" },
        { option: "15-20", value: "15-20" },
        { option: "20-30", value: "20-30" },
        { option: "30-40", value: "30-40" },
        { option: "40-50", value: "40-50" },
      ],
      placeholder: "Select",
      colClass: "col-lg-6",
    },
    {
      label: "Date",
      name: "date",
      type: "date",
      placeholder: "Select date",
      colClass: "col-lg-6",
    },
    {
      label: "Time Slot",
      name: "time",
      type: "select",
      options: [
        { option: "10am-11am", value: "10am-11am" },
        { option: "11am-12pm", value: "11am-12pm" },
        { option: "12pm-1pm", value: "12pm-1pm" },
        { option: "1pm-2pm", value: "1pm-2pm" },
        { option: "2pm-3pm", value: "2pm-3pm" },
        { option: "3pm-4pm", value: "3pm-4pm" },
        { option: "4pm-5pm", value: "4pm-5pm" },
        { option: "5pm-6pm", value: "6pm-6pm" },
      ],
      placeholder: "Select",
      colClass: "col-lg-6",
    },
  ];

  const dateInputRef = useRef(null);

  const handleClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker(); // Opens the date picker on modern browsers
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          "https://booznovenueqa.api.dweb.in/api/Account/GetTokenForStaticWebsite/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              transKey: "3vT6zQ2wX8",
              secretKey: "7bD9eF2gH4jK6mNp",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setToken(result.data);
      } catch (error) {
        console.error("Failed to fetch token", error);
      }
    };

    fetchToken();
  }, []);

  const initialFormData = formFields.reduce((acc, field) => {
    if (field.type === "select" && field.options.length > 0) {
      acc[field.name] = field.options[0].value;
    } else {
      acc[field.name] = "";
    }
    return acc;
  }, {});

  const [formData, setFormData] = useState(initialFormData);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};

    formFields.forEach((field) => {
      if (!formData[field.name]) {
        newErrors[field.name] = `Enter correct ${field.label.toLowerCase()}`;
      } else if (
        field.type === "email" &&
        !/\S+@\S+\.\S+/.test(formData[field.name])
      ) {
        newErrors[field.name] = `Enter a valid email address`;
      } else if (
        field.name === "phoneNumber" &&
        !/^\d{10}$/.test(formData[field.name])
      ) {
        newErrors[field.name] = `Enter a valid 10-digit phone number`;
      } else if (
        field.maxLength &&
        formData[field.name].length > field.maxLength
      ) {
        newErrors[
          field.name
        ] = `${field.label} should be maximum ${field.maxLength} characters`;
      } else if (
        field.pattern &&
        !new RegExp(field.pattern).test(formData[field.name])
      ) {
        newErrors[field.name] = `Enter correct ${field.label.toLowerCase()}`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    if (!token) {
      console.error("Token not available");
      setIsSubmitting(false);
      return;
    }

    const contactData = {
      FirstName: formData.firstName,
      LastName: formData.lastName,
      Email: formData.emailAddress,
      Mobile: formData.phoneNumber,
      CompanyName: formData.companyName,
      TeamSize: formData.size,
      Date: formatDate(formData.date),
      TimeSlot: formData.time,
      Message: "",
    };

    try {
      await axios.post(
        "https://booznovenueqa.api.dweb.in/api/Account/StaticWebsiteEmailForScheduleDemo/",
        contactData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setFormData(initialFormData);
      setThankyouShow(true); // Show thank you modal
    } catch (error) {
      console.error("Failed to send email", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);

  // const [selectedValue, setSelectedValue] = useState(formData[data.name]);
  console.log(formData);
  const handleChange = (event) => {
    const value = event.target.value;
    // setSelectedValue(value);
    inputChangeHandler(event);
  };

  return (
    <div>
      <NetworkErrorModal
        show={showNetworkError}
        setShow={setNetworkErrorShow}
      />
      <UnresponsiveModal
        show={showUnresponsive}
        setShow={setUnresponsiveShow}
      />
      <ThankyouModal show={showThankyou} setShow={setThankyouShow} />
      <section className="py-5 h-auto h-100vh d-flex align-items-center justify-content-center w-100">
        <div className="container d-flex align-items-center justify-content-center w-100">
          <div className="dynamic-form-wrapper">
            <div className="row">
              <div className="col-lg-8">
                <div className="left-form-wrapper">
                  <h1 className="section-title text-dark-blue text-center text-lg-start">
                    Schedule Demo
                  </h1>
                  <p className="section-subtitle text-center text-lg-start pb-3">
                    Schedule your own software demo today and experience our
                    platform first-hand.
                  </p>

                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row mb-2">
                      {formFields.map((data, index) => (
                        <div className={`${data.colClass} mb-4`} key={index}>
                          <label className="form-label text-grey2 fw-semibold">
                            {data.label}
                          </label>
                          {data.type === "textarea" ? (
                            <textarea
                              className={`form-control ${
                                errors[data.name] ? "is-invalid" : ""
                              }`}
                              name={data.name}
                              rows="3"
                              placeholder={data.placeholder}
                              value={formData[data.name]}
                              onChange={inputChangeHandler}
                              required
                            ></textarea>
                          ) : data.type === "select" ? (
                            <select
                              name={data.name}
                              className={`form-select custom-form-input ${
                                errors[data.name] ? "is-invalid" : ""
                              }`}
                              value={formData[data.name]}
                              onChange={inputChangeHandler}
                            >
                              {data.options.map((op, i) => (
                                <option
                                  value={op.value}
                                  key={i}
                                  className={
                                    formData[data.name] === op.value
                                      ? "selected-option"
                                      : ""
                                  }
                                >
                                  {op.option}
                                </option>
                              ))}
                            </select>
                          ) : data.type === "date" ? (
                            <div>
                              <input
                                type={data.type}
                                name={data.name}
                                className={`form-control custom-form-input ${
                                  errors[data.name] ? "is-invalid" : ""
                                }`}
                                placeholder={data.placeholder}
                                min={data.type === "date" ? minDate : undefined}
                                maxLength={data.maxLength}
                                pattern={data.pattern}
                                value={formData[data.name]}
                                onChange={inputChangeHandler}
                                required
                                onClick={(e) => e.currentTarget.showPicker()}
                              />
                            </div>
                          ) : (
                            <input
                              type={data.type}
                              name={data.name}
                              className={`form-control custom-form-input ${
                                errors[data.name] ? "is-invalid" : ""
                              }`}
                              placeholder={data.placeholder}
                              min={data.type === "date" ? minDate : undefined}
                              maxLength={data.maxLength}
                              pattern={data.pattern}
                              value={formData[data.name]}
                              onChange={inputChangeHandler}
                              required
                            />
                          )}
                          {errors[data.name] && (
                            <div className="invalid-feedback">
                              {errors[data.name]}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary border-rad-45 w-100 py-3"
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner animation="border" size="sm" />
                          <span className="ms-2">Submitting...</span>
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
              <Common />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScheduleDemo;
