import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "swiper/css";
import "swiper/css/pagination";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/main.scss";
import { useBaseURL } from "./components/contexts/BaseURLContext";

const preloadLinks = ["solution-page-banner-img1.9b2072054185a72e3cb9.png", "better.d8d8d8689ce672ac746d.png", "better-mobile.712b4ce432b9b0a1fb9b.png", "prod-story-img.369821eceb15ad746362.png", "prod-story-img-mobile.598fdcae1b5310bf1789.png", "DesktopAnimation.9c6ace2bb115a7b55bc4.mp4", "MobileAnimation.06cc5ca4e5a8b2d900ba.mp4"];

const baseURL = "https://booznostaticwebsitecdnendpoint.azureedge.net/";
// const baseURL = useBaseURL(); // Get the baseURL from the context

const linkElements = preloadLinks.map((link) => {
  const element = document.createElement("link");
  element.rel = "preload";
  if (link.endsWith(".mp4")) {
    element.as = "video";
    element.type = "video/mp4";
  } else {
    element.as = "image";
  }
  element.href = baseURL + link;
  return element;
});

linkElements.forEach((element) => document.head.appendChild(element));

//  <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/solution-page-banner-img1.9b2072054185a72e3cb9.png" as="image" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/better.d8d8d8689ce672ac746d.png" as="image" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/better-mobile.712b4ce432b9b0a1fb9b.png" as="image" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/prod-story-img.369821eceb15ad746362.png" as="image" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/prod-story-img-mobile.598fdcae1b5310bf1789.png" as="image" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/DesktopAnimation.9c6ace2bb115a7b55bc4.mp4" as="video" type="video/mp4" />
//     <link rel="preload" href="https://booznostaticwebsitecdnendpoint.azureedge.net/MobileAnimation.06cc5ca4e5a8b2d900ba.mp4" as="video" type="video/mp4" />

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
