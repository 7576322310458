import React from "react";
import "./FAQ.scss";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <section className="py-5">
      <div className="container">
        <h2 className="section-title text-dark-blue mb-3">Frequently Asked Questions</h2>
        {/* <div className="section-subtitle faq-subtitle">
          Explore our FAQs for quick answers to <br className="d-md-none" /> common queries!
        </div> */}

        <div className="custom-faq-accordion mt-4">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is Venue Management Software (VMS)?</Accordion.Header>
              <Accordion.Body>
                <p>Venue Management software (VMS), is a technology that assists venues in building, maintaining, and scaling their relationships with current and potential customers. At its core, a VMS consolidates all customer data into one place, serving as a vital source of information for the entire organization.</p>
                <p>Boozno.com Venue Management Software (VMS) provides greater visibility into an organization's processes, from the moment a lead discovers your venue to when you close them as a booking and beyond. It plays a crucial role in delivering a unified experience to customers by enabling customization and personalized interactions at every stage.</p>
                <p>Furthermore, VMS serves as a tool for managing sales processes and teams. It allows you to track leads, manage pipelines, prioritize deals, assess potential revenue, and cultivate stronger relationships. With a VMS, your team can delegate tasks and work together on closing more deals, bringing control and clarity to the sales process.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Why do I need a VMS?</Accordion.Header>
              <Accordion.Body>
                <p>Every venue, regardless of its size, needs to handle customer information, inquiries, and bookings while providing timely support. The key to success lies in fostering repeat customers and delivering exceptional service, and this is where Boozno.com Venue Management Software (VMS) becomes invaluable. Our VMS plays a significant role in making your brand more customer-centric and directly impact your organization's growth and revenue, while greatly reducing time spent on repetitive data entry and management.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can a VMS Increase Sales?</Accordion.Header>
              <Accordion.Body>Boozno.com VMS can help boost sales by automating tasks, streamlining processes, and syncing teams, making sales reps more productive. It also identifies bottlenecks in the sales process, allowing you to take corrective actions in time.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Why shouldn't I use spreadsheets or an online calendar to manage my Venues?</Accordion.Header>
              <Accordion.Body>
                <p>Spreadsheets and online calendars aren't tailored for venue management tasks. In an era where businesses are embracing digital solutions, relying solely on spreadsheets and online calendars can hold back your organization, sales, and teams growth. While spreadsheets can organize basic information, they fall short of meeting customer expectations and handling the complexities of a growing business.</p>
                <p>By sticking to spreadsheets, your organization risks missing out on essential tools needed by sales reps and service executives to provide excellent customer experiences. Features like real-time availability calendar, duplicate checks, booking conflict alerts, comprehensive dashboards, centralized data management, reports, imports, and various inquiry and booking automation are now standard in business, and for good reason. Spreadsheets simply can't match these requirements.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Can I get a demo of Boozno.com VMS?</Accordion.Header>
              <Accordion.Body>
                Yes, we would be happy to demonstrate Boozno.com Venue Management Software through a web conference at your convenience. The demo is a one-hour session. <br />
                You can{" "}
                <Link to="/scheduledemo" className="text-decoration-underline">
                  Schedule your software demo
                </Link>{" "}
                right now!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>How to get started with Boozno.com VMS?</Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>30-Day Free Trial</strong> <br />
                  You can sign up for the 30-day free trial of Boozno.com VMS and get hands-on experience with the software before you make up your mind. <br /> No debit/credit card is required! Just{" "}
                  <Link to="/signup" className="text-decoration-underline">
                    {" "}
                    sign up
                  </Link>{" "}
                  and get started.
                </p>
                <p></p>
                <p>
                  <strong>Simple Plans</strong> <br />
                  Choose from our straight forward monthly or yearly subscription plan. Our transparent{" "}
                  <Link to={"/pricing"} className="text-decoration-underline">
                    pricing plan
                  </Link>{" "}
                  ensures no hidden costs. Cancel anytime if you're not satisfied with our services.
                </p>
                <p>
                  <strong>Smooth Implementation</strong>
                  <br />
                  Boozno.com offers an easy onboarding process. With dedicated support and venue experts, our software's implementation time is 50% faster than the industry average for any SaaS product.
                </p>
                <p>
                  <strong>Exceptional Service</strong>
                  <br />
                  We provide top-notch dedicated support. Our technical experts are available Monday – Saturday, eight hours (10 am – 6 pm) via email, phone, and chat to assist you with any product-related queries and issues.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>What’s included in my trial plan ?</Accordion.Header>
              <Accordion.Body>
                <p>
                  The Trial plan allows you to test Boozno.com venue management software tools before committing to a paid plan. With the{" "}
                  <Link to="/pricing" className="text-decoration-underline">
                    30-day free trial,
                  </Link>{" "}
                  you have access to all features included in the 'Professional' paid plan. You can add 1 venue and up to 2 users. Note that some premium features like ‘Quotation & Proposals’, ‘Waitlist Management’, ‘F&B Menu Planning’ and ‘Data Import and Export’ are not available in the trial.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header> Do you assist in Boozno.com VMS set up and running for our business?</Accordion.Header>
              <Accordion.Body>Yes, we offer end-to-end services covering everything from requirement gathering to setup and onboarding. Our dedicated venue experts ensure the effective addressing of our users’ various requirements and needs.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Is my data safe?</Accordion.Header>
              <Accordion.Body>We’re passionate about keeping your data safe and secure. Our facilities feature stringent 24/7/365 security with video monitoring, biometric access, and advanced fire, flood, and theft monitoring systems. Our network security system employs the latest encryption and intrusion detection and prevention technologies. Check out our privacy policy to learn more.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Can I switch plans?</Accordion.Header>
              <Accordion.Body>Absolutely! Contact our support team and let them know the plan you'd like to switch to, and we'll take care of it for you instantly.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>How do you support Venue’s signing up for Boozno.com VMS?</Accordion.Header>
              <Accordion.Body>For users of our paid plans, you automatically have access to our dedicated support that promises faster responses, round-the-clock support, dedicated onboarding and more.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>Can I cancel, upgrade or downgrade anytime?</Accordion.Header>
              <Accordion.Body>Yes, you can! Upgrading or downgrading is easy. You can contact our support team, and we'll handle it for you instantly.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Can I export my collected data from Boozno.com?</Accordion.Header>
              <Accordion.Body>Yes, Boozno.com offers unlimited export on all plans except Trial.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>Which is the best Venue Management Software (VMS)?</Accordion.Header>
              <Accordion.Body>
                <p>The best VMS is the one that solves the problems you face. We might be a bit biased, but it's a fact: Boozno.com is India’s first dedicated venue management software, designed to enhance lead engagement and deliver an unmatched customer experience. With over 50 years of combined experience, our team leads the way in Venue Management SaaS with a unique approach. Boozno.com VMS can be the perfect solution for your venue, no matter the size or type.</p>
                <p>The best part? It easily scales as your team grows. </p>
                <p>
                  <Link to="/signup">Sign up</Link> for a free trial to experience Boozno.com firsthand!{" "}
                </p>
                <p>Boozno.com venue management software (VMS) is simple yet powerful software that offers all the important features without any unnecessary extras.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>Have more questions?</Accordion.Header>
              <Accordion.Body>
                <p>
                  We’re always here to help! Don’t hesitate to drop your question – <Link to="/contactus">Get in touch </Link> :)
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
