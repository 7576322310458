import React from "react";

const Security = () => {
  return (
    <section className="py-5 fw-semibold">
      <div className="legal-tab-info-wrapper">
        <h5>Boozno Security Page</h5>
        <p>At Boozno, we take the security and privacy of your data seriously. We are committed to ensuring that our platform is safe and secure for all our users. This Security Page outlines the measures we take to protect your data and maintain the security of our services.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Data Encryption</h5>
        <p>All data transmitted between your device and our servers is encrypted using industry-standard encryption protocols, such as SSL/TLS, to prevent unauthorized access. This ensures that your data remains confidential and secure during transmission.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Secure Infrastructure</h5>
        <p>The Boozno office in Mumbai is under 24×7 physical security protection. Only authorized personnel have access to the building and offices. Employees are granted access to the office only after authorization using biometric authentication. Critical locations in the office are accessible only to authorized individuals. Important documents are stored in cabinets accessible only to authorized persons. The office is equipped with surveillance cameras and its images are regularly monitored by authorized persons. A policy has been implemented to approve and regulate visitor access to the building. The office is provided with 24×7 power supply, supported by an alternative uninterrupted power supply system to ensure smooth functioning in the event of power failure.</p>
        <p>Our platform is hosted on secure servers located in data centers with stringent physical security measures. We regularly update and patch our servers to protect against known vulnerabilities and threats.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Network Security</h5>
        <p>The Boozno office network where updates are developed, deployed, monitored and managed is secured by industry-grade firewalls and antivirus software, to protect internal information systems from intrusion and to provide active alerts in the event of a threat or an incident. Firewall logs are stored and reviewed periodically.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Access Control</h5>
        <p>We implement strict access controls to limit access to your data to authorized personnel only. Access to our platform is protected by strong authentication mechanisms, such as passwords and multi-factor authentication, to prevent unauthorized access.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Data Privacy</h5>
        <p>We adhere to strict data privacy practices to ensure that your personal information is handled responsibly and in accordance with applicable privacy laws and regulations. We do not sell or share your data with third parties without your consent, except as required by law or as necessary to provide our services.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Regular Audits and Assessments</h5>
        <p>We conduct regular security audits and assessments of our platform to identify and address potential security vulnerabilities and weaknesses. We also engage third-party security experts to perform independent security assessments and penetration testing.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Incident Response</h5>
        <p>In the event of a security incident or data breach, we have established procedures and protocols in place to promptly respond to and mitigate the impact of the incident. We will notify affected users in accordance with applicable laws and regulations.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>User Education and Awareness</h5>
        <p>We provide training and educational resources to our employees to raise awareness about security best practices and the importance of safeguarding user data. We also encourage our users to take proactive measures to protect their accounts, such as using strong passwords and enabling multi-factor authentication.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Reporting Security Vulnerabilities</h5>
        <p>We encourage security researchers and users to report any security vulnerabilities or concerns they may discover in our platform. If you believe you have found a security vulnerability, please report it to us immediately at [security email]. We take all reports seriously and will promptly investigate and address any confirmed vulnerabilities.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Continuous Improvement</h5>
        <p>We are committed to continuously improving our security measures and practices to stay ahead of evolving threats and challenges. We regularly review and update our security policies and procedures to ensure that they remain effective and up-to-date.</p>
        <p>If you have any questions or concerns about the security of our platform, please contact us at legal@boozno.com. Your security and privacy are our top priorities.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Customer Controls for Security</h5>
        <ul>
          <li>
            <p>Choose a unique, strong password and protect it.</p>
          </li>
          <li>
            <p>Use multi-factor authentication.</p>
          </li>
          <li>
            <p>Use the latest browser versions, mobile OS and updated mobile applications to ensure they are patched against vulnerabilities and to use latest security features.</p>
          </li>
          <li>
            <p>Exercise reasonable precautions while sharing data from our cloud environment.</p>
          </li>
          <li>
            <p>Classify your information into personal or sensitive and label them accordingly.</p>
          </li>
          <li>
            <p>Monitor devices linked to your account, active web sessions, and third-party access to spot anomalies in activities on your account, and manage roles and privileges to your account.</p>
          </li>
          <li>
            <p>Be aware of phishing and malware threats by looking out for unfamiliar emails, websites, and links that may exploit your sensitive information by impersonating Boozno or other services you trust.</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Security;
