import React from "react";
import img from "../../../../assets/img/tab-img-1.png";
import { GreenCheckIcon } from "../../../../assets/img/svg";
import greentick from "../../../../assets/img/green-tick-icon.svg";

const TabContent = ({ content }) => {
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };
  const pointsChunks = chunkArray(content.points, 1);
  return (
    <div className="custom-tab-content-container">
      <h4 className="text-dark-blue">{content.title}</h4>
      <p className="tab-content">{content.content}</p>
      <div className="row mt-4">
        <div className="col-lg-4 d-flex align-items-center">
          <div className="tab-list d-block d-md-none mb-4">
            <div className="row">
              {pointsChunks.map((item, index) => (
                <div className="col-6 pe-0" key={index}>
                  {item.map((i, ind) => (
                    <div key={ind} className="tab-content-list-item mb-3 text-darkgrey ">
                      {/* <GreenCheckIcon /> */}
                      <img src={greentick} alt="" />
                      <div className="tab-item-list-text ms-1">{i}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="tab-list d-none d-md-block">
            {content.points.map((item, index) => (
              <div key={index} className="d-flex align-items-center mb-3 text-darkgrey ">
                <GreenCheckIcon />
                <div className="ms-1 solution-tab-item-title">{item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-8 px-0 px-md-2 d-flex justify-content-center">
          <img src={content.img} className="tab-content-img" alt="" />
        </div>
      </div>
    </div>
  );
};

export default TabContent;
