import React from "react";

const CookiesPolicy = () => {
  return (
    <section className="py-5 fw-semibold">
      <div className="legal-tab-info-wrapper">
        <h5>Boozno Cookie Policy</h5>
        <p>This Cookie Policy explains how Boozno i.e. our Software as a Service (SaaS) platform uses cookies and similar technologies to provide you with the best possible experience when you visit our website or use our services. By using our platform, you consent to the use of cookies in accordance with this Cookie Policy.</p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>What are Cookies?</h5>
        <p>Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and to provide information to the owners of the site. Cookies help us improve your experience and tailor our services to your needs.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>How We Use Cookies?</h5>
        <p className="mb-2">We use cookies for the following purposes:</p>
        <ol>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Essential Cookies: </span>These cookies are necessary for the proper functioning of our website and services. They enable you to navigate our platform and use its features. Without these cookies, our website may not function properly.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Analytics Cookies: </span>These cookies allow us to analyze how users interact with our platform, which helps us improve its performance and usability. We use various tools to gather information about website traffic and usage patterns.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Functional Cookies: </span> These cookies enable us to remember your preferences and settings, such as language preferences and login information, so you don't have to re–enter them every time you visit our platform.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Advertising Cookies: </span> We may use cookies to deliver personalized advertisements to you based on your interests and browsing behavior. These cookies may track your browsing activity across different websites.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">Third–Party Cookies: </span> Our platform may contain content and services provided by third parties, such as social media plugins and embedded videos. These third parties may also use cookies to collect information about your interactions with their content.
            </p>
          </li>
        </ol>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Your Cookie Choices</h5>
        <p className="mb-0">You have the right to choose whether or not to accept cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, please note that disabling cookies may affect the functionality of our website and services.</p>
        <p>You can also control your cookie preferences through our cookie consent management tool, which allows you to accept or reject specific categories of cookies.</p>
      </div>

      <div className="legal-tab-info-wrapper">
        <h5>Changes to this Cookie Policy</h5>
        <p>We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the updated policy on our website or by other means of communication.</p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>Contact Us</h5>
        <p>
          If you have any questions or concerns about our use of cookies, please contact us at{" "}
          <a href="mailto:legal@boozno.com" className="text-blue text-decoration-underline">
            legal@boozno.com.
          </a>{" "}
        </p>
      </div>
    </section>
  );
};

export default CookiesPolicy;
