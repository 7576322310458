import React from "react";

const TermOfService = () => {
  return (
    <section className="py-5 fw-semibold">
      <div className="legal-tab-info-wrapper">
        <h5>Terms of Service</h5>
        <p>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY AND CONFIRM YOUR
          ACCEPTANCE BEFORE AVAILING OUR SERVICES.
        </p>
        <p>
          These Terms of Service describe the Services we will provide to you,
          how we will work together, and other aspects of our business
          relationship.
        </p>
        <p>
          We request you to read the terms below carefully before confirming
          your acceptance thereof. You must be of legal age to enter into a
          binding agreement in order to accept the Agreement. Upon your
          acceptance, these Terms of Service form a legally binding agreement
          between you and Boozno Technologies Private Limited. Our Services are
          available to you only upon your acceptance of these Terms of Service.
        </p>
        <p>
          In case any of the terms are not acceptable to you, please do not
          proceed to use any of our Services. BY ACCESSING OR USING OUR SERVICES
          IN ANY WAY, YOU AGREE TO BE BOUND BY ALL THE APPLICABLE TERMS OF
          SERVICE.
        </p>
        <p>
          We periodically update these terms and we will let you know through
          email, and by posting a revised copy on our website. You agree to
          review these Terms of Service on a regular basis and always remain in
          compliance.
        </p>
        <p>
          THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
          (HEREINAFTER “YOU” or “YOUR”) AND BOOZNO TECHNOLOGIES PRIVATE LIMITED
          (HEREINAFTER “BOOZNO”) GOVERNING YOUR USE OF BOOZNO
        </p>
        <p>
          This Agreement consists of the following terms and conditions
          (hereinafter the “General Terms”) and terms and conditions, if any,
          specific to use of individual Services (hereinafter the “Service
          Specific Terms”). In the event of a conflict between the General Terms
          and Service Specific Terms, the Service Specific Terms shall prevail.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>1. DEFINITIONS</h5>
        <ol className="no-numbers">
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.1.</span> "
              <span className="text-title-grey fw-bold">Boozno</span>", "
              <span className="text-title-grey fw-bold">we</span>", "
              <span className="text-title-grey fw-bold">us</span>" or "
              <span className="text-title-grey fw-bold">our</span>" shall mean
              Boozno Technologies Private Limited, a company incorporated under
              the Companies Act, 1956, with its registered office at B–1103,
              Ashford Royale, South Samuel Marg, Mulund–Goregaon Link Road,
              Nahur West, Mumbai – 400 078.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.2.</span> "
              <span className="text-title-grey fw-bold">You</span>", "
              <span className="text-title-grey fw-bold">your</span>" or "
              <span className="text-title-grey fw-bold">Customer</span>" shall
              mean an individual or legal entity who is signing up for any kind
              of Services from us, irrespective of the nature or duration of the
              Services. Customer’s details, including name of the contracting
              entity and the authorized representative, are as provided in the
              accompanying Order Form.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.3.</span> "
              <span className="text-title-grey fw-bold">Affiliate</span>" means,
              (i) with respect to any party other than a natural person, any
              other person who holds or in which such party holds 50% or more of
              the paid-up share capital, directly or indirectly, or is
              controlling, controlled by or under common control with such
              party; (ii) in the case of any party that is a natural person, any
              other person who is a relative of such party as per the provisions
              of the act. for the purposes of this definition, the term
              “control” (including with correlative meaning, the terms
              “controlled by” and “under common control” with) as applied to any
              party, (i) means the (a) ownership or possession, directly or
              indirectly, of more than one half of the voting power of an
              enterprise, or (b) control of the composition of the board of
              directors in the case of a company or of the composition of the
              corresponding governing body in case of any other enterprise, or
              (c) a substantial interest in voting power and the power to
              direct, by statute or agreement, the financial and/or operating
              policies of the Person; and (ii) shall include the ownership or
              possession, directly or indirectly, of the power to direct or
              cause the direction of the management of that Person whether
              through ownership of voting securities or otherwise.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.4.</span> "
              <span className="text-title-grey fw-bold">Billing Cycle</span>"
              shall mean a duration cycle (like month, quarter, year) for which
              billing is done in one go, as indicated in the Order Form.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.5.</span> "
              <span className="text-title-grey fw-bold">
                Consulting Services
              </span>
              " shall mean the professional services provided to the Customer by
              Boozno, which may include training services, installation,
              integration or consulting services, the details of which shall be
              captured in Order Forms executed between the Parties from time to
              time under the terms of these Terms of Service and appended as
              annexures to these Terms of Service.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.6.</span> "
              <span className="text-title-grey fw-bold">Customer Data</span>"
              shall mean any information submitted to Boozno platform by the
              Customer.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.7.</span> "
              <span className="text-title-grey fw-bold">Disclosing Party</span>"
              shall have the meaning set out in Clause 13.1.1.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.8.</span> "
              <span className="text-title-grey fw-bold">Effective Date</span>"
              shall mean the date of your acceptance of these Terms of Service.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.9.</span> "
              <span className="text-title-grey fw-bold">Force Majeure</span>"
              shall mean an act of war, hostility, sabotage, act of God,
              epidemic, pandemic, electrical, internet or telecommunication
              outage, cyber-attacks, government or regulatory restrictions,
              change in law applicable to the Services or any other event
              outside the reasonable control of Boozno, that adversely affects
              the provision of Services.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.10.</span> "
              <span className="text-title-grey fw-bold">Boozno Platform</span>"
              shall mean the suite of applications available at www.boozno.com
              or any of its sub-domains or any other URL/location made available
              by Boozno.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.11.</span> "
              <span className="text-title-grey fw-bold">Order Form</span>" shall
              mean the form submitted by you, with your details and the Services
              opted by you, with relevant Service terms, pricing and payment
              terms being set out accordingly. Separate Order Forms may be
              submitted for different Subscription Services and Order Forms may
              be updated or modified from time to time with mutual consent.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.12.</span> "
              <span className="text-title-grey fw-bold">Person</span>" means any
              natural person, sole proprietorship, corporation, partnership,
              limited liability company, firm, joint venture, association,
              joint-stock company, trust, unincorporated organization,
              governmental entity, or any other entity.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.13.</span> "
              <span className="text-title-grey fw-bold">Planned Downtime</span>"
              shall mean the period during which the Services may be shut down
              for planned maintenance of Boozno to the extent possible and
              reasonable, such downtime will be scheduled during non-business
              hours for majority of Boozno’s customers such as weekends and
              public holidays and at least 24 (twenty-four) hours’ prior notice
              will be provided.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.14.</span> "
              <span className="text-title-grey fw-bold">Receiving Party</span>"
              shall have the meaning set out in Clause 13.1.1.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.15.</span> "
              <span className="text-title-grey fw-bold">Start Date</span>" shall
              mean the date of commencement of the Subscription Services.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.16.</span> "
              <span className="text-title-grey fw-bold">Subscription Fees</span>"
              shall mean the fees payable by the Customer for the Subscription
              Services.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.17.</span> "
              <span className="text-title-grey fw-bold">
                Subscription Services
              </span>
              " shall mean the provisions of access to the Boozno Platform as
              per the scope, limits and features that the Customer has
              subscribed to, as captured in the relevant Order Form.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.18.</span> "
              <span className="text-title-grey fw-bold">Subscription Term</span>"
              shall mean the initial term of the Subscription Services
              commencing from the Start Date, as specified in the relevant Order
              Form, and each subsequent renewal term (if any).
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">1.19.</span> "
              <span className="text-title-grey fw-bold">Users</span>" mean
              individuals authorized by Customer to access Subscription
              Services.
            </p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>2. DESCRIPTION OF SERVICE</h5>
        <p>
          We provide cloud software and applications for businesses, including
          associated offline and mobile applications. You may use the Services
          for your personal and business use or for internal business purposes
          in the organization that you represent. You may connect to the
          Services using any Internet browser supported by the Services. You are
          responsible for obtaining access to the Internet and the equipment
          necessary to use the Services. You can create and edit content with
          your user account and if you choose to do so, you can publish and
          share such content.
        </p>

        <h5>3. SCOPE OF SERVICES</h5>

        <h6>3.1. Subscription Services</h6>
        <ol className="no-numbers">
          <li>
            <p>
              <span className="text-title-grey fw-bold">3.1.1.</span> During the
              Subscription Term, we will grant the Customer a non-transferable,
              non-exclusive right to permit Users authorized by the Customer to
              access and use the Boozno Platform for its internal business
              purposes in accordance with these Terms of Service, the relevant
              Order Form and all laws and regulations applicable to the
              Customer. The Customer shall not use the right granted hereunder
              or permit it to be used, for purposes of product evaluation,
              benchmarking or other comparative analysis intended for
              publication.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">3.1.2.</span> You may, at
              any time, subscribe to additional features of the Subscription
              Services (existing features or new features that may be made
              available by us from time to time) by executing an additional
              Order Form.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">3.1.3.</span> We may
              update the Boozno Platform from time to time, without adversely
              affecting the Subscription Services. We, however, are under no
              binding obligation to release new features or updates to the
              Boozno. We make no representations as to future features and
              functionalities, irrespective of any public announcements or
              comments in this regard.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">3.1.4.</span>{" "}
              Subscription Services will not be made available during Planned
              Downtime or Force Majeure.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">3.1.5.</span> Boozno may
              provide sample files and applications for the purpose of
              demonstrating the possibility of using the Services effectively
              for specific purposes. The information contained in any such
              sample files and applications consists of random data. Boozno
              makes no warranty, either express or implied, as to the accuracy,
              usefulness, completeness or reliability of the information or the
              sample files and applications.
            </p>
          </li>
        </ol>

        <h6>3.2. Third Party Service Providers or Third Party Software</h6>
        <p>
          We may use third-party service providers, including application
          service providers and hosting service providers, for rendering any of
          the Services hereunder without seeking further consent from you, but
          we will continue to be responsible for such Services. Boozno will
          however, not be responsible for any third-party service providers
          engaged by you or any third-party software that may be procured by
          you, whether with or without our consent and notwithstanding that the
          same may be integrated with the Services.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>4. FREE TRIAL</h5>
        <p>
          If you register for a free trial of one or more Services, Boozno will
          make the applicable Services available to you on a trial basis free of
          charge until the earlier of (i) the end of the free trial period of
          the applicable Services (unless terminated earlier by you), (ii) the
          start date of the paid subscription period for the applicable
          Services, or (iii) termination by Boozno in its sole discretion. Any
          data that you enter into the Services, and any customizations made to
          the Services during the free trial will be permanently lost unless you
          (i) purchase the corresponding paid subscription plan for the account,
          (ii) purchase applicable Service upgrades, or (iii) export such data
          before the end of the trial period. Notwithstanding anything contained
          in this Section, Services are offered as-is during the free trial,
          without any warranty, covenant, support or liability whatsoever, to
          the extent permitted by law.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>5. USER SIGN UP / SUBSCRIPTION OBLIGATIONS</h5>
        <p>
          You need to sign up for a user account by providing all required
          information in order to access or use the Services. If you represent
          an organization and wish to use the Services for corporate internal
          use, we recommend that you, and all other users from your
          organization, sign up for user accounts by providing your corporate
          contact information. In particular, we recommend that you use your
          corporate email address. You agree to: (i) provide true, accurate,
          current and complete information about yourself as prompted by the
          sign up process; and (ii) maintain and promptly update the information
          provided during sign up to keep it true, accurate, current, and
          complete. If you provide any information that is untrue, inaccurate,
          outdated, or incomplete, or if Boozno has reasonable grounds to
          suspect that such information is untrue, inaccurate, outdated, or
          incomplete, Boozno may terminate your user account and refuse current
          or future use of any or all of the Services.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>6. RESTRICTIONS ON USE</h5>
        <ol className="no-numbers">
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.1.</span> Transfer
              the Services or otherwise make it available to any third party.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.2.</span> Provide any
              service based on the Services without prior written permission.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.3.</span> Allow user
              licenses to be shared or used by more than one individual other
              than by way of reassigning the user license to a new user.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.4.</span> Except as
              permitted under applicable law, attempt to disassemble, reverse
              engineer or decompile the Services.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.5.</span> Use the
              third party links to sites without agreeing to their website terms
              & conditions.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.6.</span> Post links
              to third party sites or use their logo, company name, etc. without
              their prior written permission.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.7.</span> Attempt to
              gain unauthorized access to the Services or its related systems or
              network.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.8.</span> Use the
              Services in any manner that could damage, disable, overburden,
              impair or harm any server, network, computer system, resource of
              Boozno.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.9.</span> Use the
              Services to send or store material containing software viruses,
              worms or other harmful computer codes, files, scripts or programs.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.10.</span> Use the
              Services in any manner that interferes with or disrupts the
              integrity, security or performance of the Services, its components
              and the data contained therein.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.11.</span> Host,
              display, upload, modify, publish, transmit, store, update or share
              any information that belongs to another person or entity and to
              which you do not have any right, including personal or
              confidential information of any person or entity with respect to
              which you do not have consent or permission from such person or
              entity.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.12.</span> Violate
              any applicable local, state, national or international law.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.13.</span> Use the
              Services for any form of competitive or benchmarking purposes.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.14.</span> Remove or
              obscure any proprietary or other notices contained in the
              Services.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.15.</span> Use our
              services in any manner that threatens the unity, integrity,
              defence, security or sovereignty of India, friendly relations of
              India with other countries, or public order, or causes incitement
              to the commission of any cognizable offence or prevents
              investigation of any offence or is insulting other countries.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.16.</span> Create a
              false identity to mislead any person as to the identity or origin
              of any communication.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.17.</span> Use the
              services for transmitting information that is patently false and
              untrue, and is written or published in any form, with the intent
              to mislead or harass a person, entity or agency for financial gain
              or to cause any injury to any person.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.18.</span> Use the
              services in a manner that relates to or encourages any activity
              prohibited by law in India.
            </p>
          </li>
          <li>
            <p>
              <span className="text-title-grey fw-bold">6.1.19.</span> You are
              responsible to ensure that the Subscription Services are used in
              accordance with these Terms of Service and will notify us
              immediately of any unauthorized use of your account or Users’
              identifications and passwords by sending an email to{" "}
              <a
                href="mailto:legal@boozno.com"
                className="text-decoration-underline"
              >
                legal@boozno.com
              </a>
              .
            </p>
          </li>
        </ol>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>7. SPAMMING AND ILLEGAL ACTIVITIES</h5>
        <p>
          You agree to be solely responsible for the contents of your
          transmissions through the Services. You agree not to use the Services
          for illegal purposes or for the transmission of material that is
          unlawful, defamatory, insulting, harassing, libelous, invasive of
          another's privacy (including bodily privacy), abusive, threatening,
          harmful, vulgar, pornographic, paedophilic, harmful to children,
          obscene, racially or ethnically objectionable, or is otherwise
          objectionable, offends religious sentiments, promotes racism, contains
          viruses or malicious code, or that which infringes or may infringe
          intellectual property or other rights of another. You agree not to use
          the Services for the transmission of "junk mail", "spam", "chain
          letters", “phishing” or unsolicited mass distribution of email. We
          reserve the right to terminate your access to the Services if there
          are reasonable grounds to believe that you have used the Services for
          any illegal or unauthorized activity.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>8. THIRD PARTY APPLICATIONS</h5>
        <p>
          Boozno integrates with many third party applications (hereinafter
          "Third Party Applications"). Access and use of the Third Party
          Applications may require acceptance of terms of service and privacy
          policies applicable to such Third Party Applications (hereinafter
          "Third Party Terms"). You are responsible for reading and
          understanding the Third Party Terms before accessing or using any
          Third Party Application. You acknowledge and agree that Boozno is not
          liable for any Third Party Applications. While we will try to provide
          you with advance notice, whenever reasonably possible, you acknowledge
          and agree that Boozno may, at any time and in our sole discretion, and
          without any notice to you, suspend, restrict or disable access to or
          remove from Boozno Services, any Third Party Application, without any
          liability to you, including without limitation for any loss of
          profits, revenue, data, goodwill or other intangible losses.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>9. FEES AND PAYMENTS</h5>
        <p>
          The Services are available under subscription plans of various
          durations. Before the end of each subscription period, our team will
          get in touch with the customer for the renewal. If you do not wish to
          renew the subscription, you must inform us at least seven days prior
          to the renewal date. The Customer will be making the payment offline
          via a payment link sent to them or by other means as decided by
          Boozno.
        </p>
        <p>
          From time to time, we may change the price of any Service or charge
          for use of Services that are currently available free of charge. Any
          increase in charges will not apply until the expiry of your then
          current billing cycle. You will not be charged for using any Service
          unless you have opted for a paid subscription plan.
        </p>
        <p>
          In the event any tax such as GST, VAT, sales tax or the like is
          chargeable by Boozno in accordance with any local, state, provincial
          or foreign laws with respect to your subscription to our Services
          ("Taxes"), Boozno will invoice you for such Taxes. You agree to pay
          Boozno such Taxes in addition to the subscription fees. Boozno shall
          provide you with an invoice in the format prescribed by the applicable
          local, state, provincial or foreign laws to help you avail the
          applicable input tax credit for the Taxes so paid.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>10. Ownership And Proprietary Rights</h5>

        <h6>10.1. Boozno Rights</h6>
        <p>
          We own or have rights to all worldwide intellectual property rights in
          and to the Boozno Platform and the Services (including all
          customizations, derivatives, adaptations or improvements thereof), and
          all copyrights, patents, trademarks, service marks and trade secrets
          in relation thereto, whether registered or not. All suggestions,
          enhancement requests, feedback, recommendations or other inputs
          provided by you or any other party relating to the Boozno Platform or
          Services shall be owned by us, and you hereby assign, perpetually and
          worldwide, free of royalties or any payments, all rights in the same
          in favor of us and shall, at our expense, execute such documents as
          are necessary to accomplish the foregoing ownership. Such assignment
          shall not lapse, notwithstanding that we do not make use of the same
          within the timelines prescribed by law, if any. Any rights not
          expressly granted herein are reserved by us.
        </p>
        <h6>10.2. Customer Rights</h6>
        <p>
          You shall have ownership rights over all Customer Data and you hereby
          grant us and our licensors the worldwide, limited, non-exclusive
          license to access and use the same for the purpose of rendering the
          Services. You shall be solely responsible for the accuracy, quality,
          integrity, legality, reliability, appropriateness and intellectual
          property ownership or right to collect and use the Customer Data.
        </p>
        <h6>10.3. Using Customer’s name and logo</h6>
        <p>
          You hereby permit us to use your name, website address and logo in our
          marketing material including website, email campaigns, brochures etc.
          during and after active engagement.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>11. Organization Accounts and Administrators</h5>

        <p>
          When you sign up for an account for your organization you may specify
          one or more administrators. The administrators will have the right to
          configure the Services based on your requirements and manage end users
          in your organization account. If your organization account is created
          and configured on your behalf by a third party, it is likely that such
          third party has assumed administrator role for your organization. Make
          sure that you enter into a suitable agreement with such third party
          specifying such party’s roles and restrictions as an administrator of
          your organization account.
        </p>

        <p>
          You are responsible for (i) ensuring confidentiality of your
          organization account password, (ii) appointing competent individuals
          as administrators for managing your organization account, and (iii)
          ensuring that all activities that occur in connection with your
          organization account comply with this Agreement. You understand that
          Boozno is not responsible for account administration and internal
          management of the Services for you.
        </p>

        <p>
          You are responsible for taking necessary steps for ensuring that your
          organization does not lose control of the administrator accounts. You
          may specify a process to be followed for recovering control in the
          event of such loss of control of the administrator accounts by sending
          an email to <a href="mailto:legal@boozno.com">legal@boozno.com</a>,
          provided that the process is acceptable to Boozno. In the absence of
          any specified administrator account recovery process, Boozno may
          provide control of an administrator account to an individual providing
          proof satisfactory to Boozno demonstrating authorization to act on
          behalf of the organization. You agree not to hold Boozno liable for
          the consequences of any action taken by Boozno in good faith in this
          regard.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>12. Modification of Terms of Service</h5>

        <p>
          We may modify this Agreement upon notice to you at any time through a
          service announcement or by sending email to your primary email
          address. If we make significant changes to the Agreement that affect
          your rights, you will be provided with at least 30 days advance notice
          of the changes by email to your primary email address. You may
          terminate your use of the Services by providing Boozno notice by email
          within 30 days of being notified of the availability of the modified
          Agreement if the Agreement is modified in a manner that substantially
          affects your rights in connection with use of the Services. In the
          event of such termination, you will be entitled to prorated refund of
          the unused portion of any prepaid fees. Your continued use of the
          Service after the effective date of any change to the Agreement will
          be deemed to be your agreement to the modified Agreement.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>13. Confidentiality</h5>

        <h6>13.1. Confidential Information</h6>
        <p>
          As used herein, “Confidential Information” means all confidential
          information disclosed by a party (“Disclosing Party”) to the other
          party (“Receiving Party”), whether orally or in writing, that is
          designated as confidential or that reasonably should be understood to
          be confidential given the nature of the information and the
          circumstances of disclosure. Customer’s Confidential Information shall
          include Customer Data; Boozno’s Confidential Information shall include
          the Boozno Platform and Services; and Confidential Information of each
          Party shall include the terms and conditions of these Terms of Service
          and all Orders Forms, as well as business and marketing plans,
          technology and technical information, product plans and designs, and
          business processes disclosed by such Party. However, Confidential
          Information shall not include any information that (i) is or becomes
          generally known to the public without breach of any obligation owed to
          the Disclosing Party; (ii) was known to the Receiving Party prior to
          its disclosure by the Disclosing Party without breach of any
          obligation owed to the Disclosing Party; (iii) is received from a
          third party without breach of any obligation owed to the Disclosing
          Party; or (iv) was independently developed by the Receiving Party.
        </p>

        <h6>13.2. Protection of Confidential Information</h6>
        <p>
          The Receiving Party shall use the same degree of care to protect
          Confidential Information that it uses to protect the confidentiality
          of its own confidential information of like kind (but in no event less
          than reasonable care). It shall not use any Confidential Information
          of the Disclosing Party for any purpose outside the scope of these
          Terms of Service and except as otherwise authorized by the Disclosing
          Party in writing, limit access to Confidential Information of the
          Disclosing Party to those of its and its service providers’ employees,
          consultants, contractors and agents who need such access for purposes
          consistent with these Terms of Service and who are bound by
          confidentiality obligations that are no less stringent than those
          herein.
        </p>

        <h6>
          13.3. The provisions of this Clause 12 (Confidentiality) shall survive
          the expiry or termination of these Terms of Service.
        </h6>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>14. Customer Data Protection</h5>
        <p>
          <span class="text-title-grey fw-bold">14.1</span> &nbsp; We shall adopt
          and maintain industry standard organizational and technical safeguards
          for the protection of the security, confidentiality and integrity of
          Customer Data. We shall process the Customer Data only for the purpose
          of rendering the Services, as per the privacy policy set forth at
          boozno.com/privacy-policy.
        </p>
        <p>
          <span class="text-title-grey fw-bold">14.2</span>&nbsp; We do not
          determine whether Customer Data includes information subject to any
          specific law or regulation in Customer’s local jurisdiction. In case
          you require us to implement any specific measures, given the nature of
          Customer Data or to comply with any specific laws or regulations
          applicable to your business or in your local jurisdiction, you shall
          bring the same to our notice and we will mutually agree upon the
          feasibility, scope and pricing for such measures.
        </p>
        <p>
          <span class="text-title-grey fw-bold">14.3</span>&nbsp; As you collect
          the Customer Data and determine the mode of processing the same using
          the Services, you shall be the controller, and we the processor, for
          the purpose of applicable data privacy laws. You shall be responsible
          for ensuring that it complies with applicable data protection laws
          pertaining to collection and transfer of personal data as the
          controller of the Customer Data.
        </p>
        <p>
          <span class="text-title-grey fw-bold">14.4</span>&nbsp; We may monitor
          use of the Subscription Services by all its customers and use such
          data in an aggregate and anonymous manner, without publishing any
          personally identifiable information.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>15. Indemnity</h5>
        <p>
          Either party shall defend, indemnify and hold the other party harmless
          against any loss, damage or costs (including reasonable attorneys’
          fees) incurred in connection with claims, demands, suits, or
          proceedings made or brought against the other party: (i) due to breach
          of the representation and warranties set forth in these Terms of
          Service; (ii) by a third party alleging that the use of the Services
          as contemplated hereunder infringes the intellectual property rights
          or data privacy rights of such third party; provided that the
          indemnified party (a) promptly gives written notice of the claim to
          the indemnifying party; (b) gives the indemnifying party control of
          the defense and settlement of the claim (provided that the
          indemnifying party may not settle any claim unless it unconditionally
          releases the indemnified party of all liability); and (c) provides to
          the indemnifying party, at the indemnifying party’s cost, all
          reasonable assistance.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>16. Disclaimers & Limitations of Liability</h5>

        <h6>16.1. Disclaimer of Warranties</h6>
        <p>
          Except as expressly undertaken herein, the services are provided “AS
          IS” and Boozno, to the extent permitted by law, makes no
          representations or warranties about the suitability, reliability,
          availability, timeliness, security or accuracy of the services or data
          made available from the services. Boozno makes no warranty that the
          services will be uninterrupted, timely, secure, or error free. use of
          any material downloaded or obtained through the use of the services
          shall be at your own discretion and risk and you will be solely
          responsible for any damage to your computer system, mobile telephone,
          wireless device or data that results from the use of the services or
          the download of any such material. No advice or information, whether
          written or oral, obtained by you from Boozno, its employees or
          representatives shall create any warranty not expressly stated in the
          Agreement.
        </p>

        <h6>16.2. Limitation of Liability</h6>
        <p>
          In no event shall either party, its directors, officers or any of its
          affiliates be liable for (i) loss of profit, loss of business, loss of
          business opportunity, loss of revenue, loss of reputation or goodwill,
          in each whether actual or anticipated; (ii) loss, theft or corruption
          of data; and (iii) any special, punitive, indirect, consequential or
          incidental damages (including substitution of services) arising out of
          or relating to these terms of service any third party services
          delivered in connection herewith even if previously advised of the
          possibility of such damages and regardless of whether such liability
          arises out of contract, negligence, tort, strict liability or any
          other theory of legal liability, and in no event shall either party’s
          cumulative liability hereunder (other than for payment of fees due)
          exceed the amount paid or payable by customer to Boozno in the six
          month period immediately preceding any such claim.
        </p>
      </div>
      <div className="legal-tab-info-wrapper">
        <h5>17. General Provisions</h5>

        <h6>17.1. Force Majeure</h6>
        <p>
          Neither party shall be responsible for failure or delay in performance
          if caused by Force Majeure, except in respect of payment obligations
          hereunder. Each party will use reasonable efforts to mitigate the
          effect of a Force Majeure event. Service Fees shall not accrue for the
          period during which the Services are not rendered due to Force
          Majeure.
        </p>

        <h6>17.2. Compelled Disclosure</h6>
        <p>
          Boozno reserves the right at all times to disclose any information,
          including Customer Data and Customer’s Confidential Information, when
          compelled to so by any applicable law, regulation, legal process or
          governmental request.
        </p>

        <h6>17.3. Severability</h6>
        <p>
          If any provision of these Terms of Service is held by a court of
          competent jurisdiction to be contrary to law, or for any reason
          invalid, void or unenforceable, the remainder of the provisions shall,
          to the extent practicable, remain in full force and effect and parties
          will negotiate in good faith to amend such invalid, void or
          unenforceable provision to give effect to the intended purpose of such
          provision in accordance with applicable laws.
        </p>

        <h6>17.4. Relationship Between the Parties</h6>
        <p>
          No joint venture, partnership, employment, or agency relationship is
          created between Boozno and Customer as a result of these Terms of
          Service or use of the Services.
        </p>

        <h6>17.5. Assignment</h6>
        <p>
          Either party may assign its rights hereunder in the event of a merger,
          reorganization or acquisition of all or substantially all of its
          assets; in all other cases, the other party’s prior written approval
          shall be required for assignment and the same shall not be
          unreasonably withheld.
        </p>

        <h6>17.6. NO WAIVER</h6>
        <p>
          Any waiver by a party of a breach of these Terms of Service by the
          other party shall be specific and in writing, and shall not operate as
          a waiver of any other or future breach under these Terms of Service.
        </p>

        <h6>17.7. NOTICE</h6>
        <p>
          Any notice or other communication required or permitted under this
          Terms of Service shall be given in writing to the other party via hand
          delivery, courier or by registered post acknowledgment due. Notices
          shall be effective upon receipt. However, notices pertaining to the
          use of the Services, including overuse and payments, may be sent by
          email only to the address set out below. If to the Customer: Customer
          Name, Address and Email ID as per Order Form. If to Boozno: Attn:
          Legal Department – Boozno, Boozno Technologies Private Limited,
          B–1103, Ashford Royale, South Samuel Marg, Mulund–Goregaon Link Road,
          Nahur West, Mumbai – 400 078. Email: legal@boozno.com
        </p>

        <h6>17.8. Governing Law and Dispute Resolution</h6>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of India. Parties shall try to resolve any
          Dispute arising out of or in relation to these Terms of Service by
          mutual discussions, failing which the same shall be submitted to
          arbitration under the provisions of the Arbitration and Conciliation
          Act, 1996. The parties agree that the arbitration shall be before a
          sole arbitrator appointed under the Rules. The juridical seat of
          arbitration shall be Mumbai, India. The language of arbitration shall
          be English. The law governing the arbitration proceedings shall be
          Indian law. The decision of the arbitrator shall be final and binding
          on the parties. Subject to the above, the competent courts at the seat
          shall have exclusive jurisdiction.
        </p>

        <h6>17.9. Entire Agreement</h6>
        <p>
          These Terms of Service, including its annexures and any additional
          Order Forms, modifications or addendum that may be entered into from
          time to time constitutes the entire agreement between the Parties and
          supersedes all prior and contemporaneous agreements, proposals or
          representations, written or oral, concerning its subject matter. Any
          additional or different terms set out in a purchase order or any
          future correspondence shall not be binding on Boozno. Any modification
          to an Order Form shall be in writing and mutually agreed to by the
          Parties.
        </p>

        <h6>17.10. Order of Precedence</h6>
        <p>
          In the event of any conflict between these Terms of Service and the
          terms of an Order Form, the Order Form shall prevail solely with
          respect to the subject matter thereof.
        </p>

        <h6>17.11. CONTACT INFORMATION</h6>
        <p>
          You can contact us at{" "}
          <a href="mailto:legal@boozno.com" class="text-decoration-underline">
            legal@boozno.com.
          </a>
        </p>
      </div>
    </section>
  );
};

export default TermOfService;
