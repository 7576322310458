import React, { useEffect, useState } from "react";
import "./Footer.scss";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  Logo,
  MailIcon,
  PhoneIcon,
  TwitterIcon,
  XIcon,
} from "../../../assets/img/svg";
import { Link } from "react-router-dom";
import mailIcon from "../../../assets/img/grey-mail-icon.png";
import facebook from "../../../assets/img/facebook.png";
import twitter from "../../../assets/img/twitter.png";
import instagram from "../../../assets/img/instagram.png";
import linkedin from "../../../assets/img/linkedin.png";
import { useBaseURL } from "../../contexts/BaseURLContext";

const Footer = () => {
  const baseURL = useBaseURL(); // Get the baseURL from the context

  const images = {
    facebook: `${baseURL}facebook.9be3542e70265b44dcc6.png`,
    mailIcon: `${baseURL}menu-device-img.26df9e4e810371a5b63e.png`,
    twitter: `${baseURL}journey-stepper.e338e2309f0ecd55e6e0c6c0347f7d0b.svg`,
    instagram: `${baseURL}instagram.5c1696dd0716b136bc74.png`,
    lockImg: `${baseURL}privacy-img.974c4590f967e3ed2fc33dc09fa0343e.svg`,
  };

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <footer>
      <div className="bg-blue py-5">
        <div className="container footer-container">
          <div className="row">
            <div className="col-md-4">
              <div className="logo-wrapper mb-4">
                <Logo />
              </div>
              <div className="text-light mb-3 footer-subtitle">
                For businesses who need clarity, productive communication,
                prioritisation and accountability.
              </div>
              {/* <div className="footer-socialmedia-items mb-5">
                <div className="item">
                  <Link
                    to={"https://www.facebook.com/Boozno-102555655609164/"}
                    target="_blank"
                  >
                    <img src={images.facebook} alt="" />
                  </Link>
                </div>
                <div className="item">
                  <Link to={"https://twitter.com/boozno"} target="_blank">
                    <img src={twitter} className="twitter-icon" alt="" />
                  </Link>
                </div>
                <div className="item">
                  <Link to={"https://www.instagram.com/boozno"} target="_blank">
                    <img src={images.instagram} alt="" />
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={"https://www.linkedin.com/company/boozno"}
                    target="_blank"
                  >
                    <img src={linkedin} alt="" />
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="text-white footer-link-title">Useful Links</div>
                <div className="col-5 col-md-6 mb-5 mb-lg-0">
                  <div className="footer-links text-light">
                    <Link to={"/"}>
                      <div className="item">Home</div>
                    </Link>
                    <Link to={"/solution"}>
                      <div className="item">Solutions</div>
                    </Link>
                    <Link to={"/pricing"}>
                      <div className="item">Pricing Plan</div>
                    </Link>
                    <Link to={"/aboutus"}>
                      <div className="item">About Us</div>
                    </Link>
                    <Link to={"/blog"}>
                      <div className="item">Blogs</div>
                    </Link>
                    {/* <Link to={"/"}>
                      <div className="item">Career</div>
                    </Link> */}
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="footer-links text-light">
                    <Link to={"/faqs/product"}>
                      <div className="item">FAQs</div>
                    </Link>
                    <Link to={"/talktosales"}>
                      <div className="item">Talk to Sales</div>
                    </Link>
                    <Link to={"/scheduledemo"}>
                      <div className="item">Schedule Demo</div>
                    </Link>
                    <Link to={"/contactus"}>
                      <div className="item">Contact Us</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="row">
                <div className="col-5 col-md-6">
                  <div className="text-white footer-link-title">Legal</div>
                  <div className="footer-links text-light">
                    <Link to={"/legal/termsconditons"}>
                      <div className="item">Terms of Services</div>
                    </Link>
                    <Link to={"/legal/privacypolicy"}>
                      <div className="item">Privacy Policy</div>
                    </Link>
                    <Link to={"/legal/cookiespolicy"}>
                      <div className="item">Cookie Policy</div>
                    </Link>
                    <Link to={"/legal/security"}>
                      <div className="item">Security</div>
                    </Link>
                    {/* <Link to={"/legal/mpi"}>
                      <div className="item whitespace-nowrap">Do Not Sell My Personal Info</div>
                    </Link> */}
                    {/* <div className="item">Terms of Services</div>
                    <div className="item">Privacy Policy</div>
                    <div className="item">Cookie Policy</div>
                    <div className="item whitespace-nowrap">Do Not Sell My Personal Info</div> */}
                    {/* <Link to={"/legal/gdpr"}>
                      <div className="item whitespace-nowrap">GDPR Compliance</div>
                    </Link> */}
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="text-white footer-link-title">Support</div>
                  <div className="footer-links text-light">
                    <div className="d-flex align-items-center item">
                      <div className="d-block d-md-block">
                        <MailIcon />
                      </div>
                      <img src={images.mailIcon} className="d-none d-md-none w-23" alt="" />
                      <div className="ms-2">support@boozno.com</div>
                    </div>
                    <div className="d-flex align-items-center item">
                      <PhoneIcon />
                      <div className="ms-2">+91 12345 54321</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* Weekly Newsletter */}
            {/* <div className="col-md-4">
              <div>
                <div className="text-white mb-3 fs-18">Subscribe to our weekly email newsletter</div>
                <div className="footer-subscribe-wrapper">
                  <input type="text" placeholder="Add your email address" />
                  <button className="btn btn-primary">Subscribe</button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="bg-dark-blue text-light text-center footer-copyright-text">
        Copyright © {currentYear} by Boozno.com. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
